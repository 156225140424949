#about-container {
  font-family: "Rubik";
  font-style: normal;
  line-height: 5vh;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  padding-bottom: 15vh;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }

  .project-title {
    display: flex;
    align-items: center;
    font-size: 4.5vh;
    height: 12vh;
    padding-right: 5%;
    border-bottom: 1px solid #9e9c9c;
  }

  .about-content {
    padding-right: 5%;
    padding-top: 5vh;
    width: 90vw;
    font-weight: 500;
    font-size: 3vh;

    .link-to-hilma {
      font-weight: 400;
      text-decoration: underline;
      color: var(--custom-pink);
      cursor: pointer;
      margin-right: 0.3vw;
      margin-left: 0.3vw;
    }
  }

  .first-part-text {
    margin-bottom: 5vh;
  }

  .worms-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .worm-img {
      height: 5vh;
      padding-left: 5vh;
    }
  }
}
.credits-title {
  padding-right: 5%;
  padding-top: 5vh;
  font-size: 2.5vh;
}
.credits-list {
  padding-right: 5%;
  padding-top: 2vh;
  font-size: 2.5vh;
}

.link-to-login {
  padding-right: 5%;
  padding-top: 2vh;
  font-size: 2.5vh;
  &:link {
    color: var(--custom-dark-blue);
    background-color: transparent;
    text-decoration: none;
  }

  &:visited {
    color: var(--custom-pink);
    background-color: transparent;
    text-decoration: none;
  }

  &:hover {
    color: var(--custom-medium-blue);
    background-color: transparent;
    text-decoration: underline;
  }

  &:active {
    color: var(--custom-light-blue);
    background-color: transparent;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  #about-container {
    .project-title {
      padding-top: vh(3);
      font-size: 3vh;
      padding-right: 6%;
      padding-left: 6%;
      box-shadow: unset;
    }

    .about-content {
      padding-right: 6%;
      padding-left: 6%;
      width: 100vw;
    }

    // .first-part-text {
    //   // padding-top: unset;
    // }
  }
}

@media only screen and (max-width: 298px) {
  #about-container {
    .project-title {
      font-size: 2.7vh;
    }

    .about-content {
      font-size: 2.5vh;
    }
  }
}
