.tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
        display: none;
        width: 180px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 1.5vh;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -85px;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 2.75vh;
        line-height: 2.8vh;
        font-size: 2.5vh;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }
    }

    &:hover,
    &:focus {
        .tooltiptext {
            display: initial;
            opacity: 1;
        }
    }

    img {
        width: 95%;
        height: 95%;
    }
}
