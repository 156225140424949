@import "../style/changing-sizes.scss";

.choose-class-popup {
    .choose-class-popup-container {
        height: vh(50);
        width: 40vw;

        .divClose {
            display: flex;
            justify-content: flex-start;
            padding: vh(2);
            padding-right: 0;
            .close {
                height: vh(3);
                cursor: pointer;
            }
        }

        .flex-center {
            display: flex;
            justify-content: center;
            .choose-class-inputs {
                width: 80%;
                align-self: center;
                .choose-option {
                    display: flex;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: vh(5);
                    align-items: center;
                    transition: visibility 0s, opacity 0.5s ease-in-out;
                    font-size: vh(3);

                    .option-label {
                        font-size: vh(3);
                    }

                    .react-select-close-class {
                        width: 20vw;
                        text-indent: 7px;

                        .css-1pahdxg-control {
                            outline: none;
                            border-color: hsl(0, 0%, 80%);
                            box-shadow: unset;
                        }

                        .css-26l3qy-menu {
                            border-radius: vh(2);
                        }
                    }

                    .css-2b097c-container {
                        * {
                            cursor: pointer;
                            border-radius: vh(4);
                        }
                    }
                }
            }
        }
    }

    .submit-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        .choose-class-button {
            padding: vh(1.2);
            font-size: vh(2.5);
            width: 12vw;
            margin-bottom: vh(3);
            font-family: Rubik;
        }
    }
}

@media only screen and (max-width: 768px) {
    .choose-class-popup {
        .choose-class-popup-container {
            height: vh(60);
            width: 80vw;
            .divClose {
                padding-top: vh(3);

                padding-right: 3vw;
                .close {
                    height: vh(3.5);
                }
            }

            .flex-center {
                height: 65%;
                .choose-class-inputs {
                    .choose-option {
                        .option-label {
                            min-width: unset;
                        }
                        .react-select-close-class {
                            width: 30vw;
                            height: vh(6);
                        }
                    }
                }
            }
        }

        .submit-flex {
            .choose-class-button {
                width: 24vw;
                font-size: vh(3);
                border-radius: vh(4);
            }
        }
    }
}
