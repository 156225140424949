@import "changing-sizes.scss";

.login-page {
    background-image: url("../newbackg.png");
    height: vh(100);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .login-component {
        height: vh(80);
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .login-title--container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: vh(7);
            color: white;
            text-align: center;
            
            .login-title {
                font-size: vh(5.7);
                font-weight: 600;
            }

            .login-subtitle {
                margin-top: .4rem;
            }
        }

        .login-inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: vh(27);

            .login-input {
                width: 32vw;
                border: none;
                outline: none;
                border-radius: vh(2);
                height: vh(6.5);
                box-shadow: 0px 3px 6px #00000029;
                text-indent: 15px;
                background: #f4f4f6 0% 0% no-repeat padding-box;
                opacity: 1;
                font: normal normal 500 vh(2.2) Rubik;
                font-size: vh(2.2);
                font-weight: 500;
                color: #0c3e89;
            }

            ::placeholder {
                color: #0c3e89;
                font-size: vh(2.2);
                font-weight: 600;
            }

            .login-pass {
                margin-top: vh(-2);

                .login-pass-input {
                    width: 28vw;
                    border: none;
                    outline: none;
                    height: 100%;
                    background: #f4f4f6 0% 0% no-repeat padding-box;
                    font-size: vh(2.2);
                    font-weight: 500;
                    color: #0c3e89;
                }
            }

            .login-error {
                width: 30vw;
                margin-top: vh(-3);
                text-align: right;
            }
        }

        .login-error {
            color: black;
            height: vh(1);
            font-size: vh(1.9);
            text-align: center;
            font-weight: 500;
            text-shadow: -1px 0 #e6e6e6, 0 1px #e6e6e6, 1px 0 #e6e6e6, 0 -1px #e6e6e6;
            letter-spacing: 0.5px;
        }

        .login-btns {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: vh(20);
            margin-bottom: vh(5);
            margin-top: vh(-7);

            .login-btn {
                width: 19vw;
                height: vh(6);
                font-family: Rubik;
                font-size: vh(2.5);
                font-weight: 500;
            }

            .login-btn-enter {
                background-color: #ee5c5c;
                color: white;
            }

            .login-btn-new {
                color: #48a6e1;
                background-color: white;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .login-page {
        // background-image: url("../mobileback.png");

        .login-component {
            .login-title--container {
                .login-title {
                    font-size: vh(4);
                }

                .second {
                    // margin-top: vh(-4);
                }
            }

            .login-inputs {
                .login-input {
                    width: 70vw;
                    border-radius: vh(2.5);
                }

                .login-pass {
                    .login-pass-input {
                        width: 60vw;
                        border-radius: vh(2.5);
                    }
                }

                .login-error {
                    width: 64vw;
                }
            }

            .login-btns {
                margin-top: vh(-4);

                .login-btn {
                    width: 53vw;
                    border-radius: vh(4);
                }
            }
        }
    }
}