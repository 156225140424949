.profile {
    background-color: #f2efef;
    display: flex;
    height: 30vh;
    justify-content: center;
    align-items: center;

    .profile2 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 75vw;

        .name-flex {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 15vw;

            .fullName {
                font-size: 2.7vw;
                line-height: 3vw;
                font-weight: bold;
            }
            .className {
                font-size: 2vw;
                line-height: 2.5vw;
                font-weight: bold;
            }
        }

        .listening {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 15vw;

            font-size: 1.5vw;
            line-height: 2.5vw;
            font-weight: bold;

            span {
                font-size: 2.7vw;
                line-height: 3.5vw;
                font-weight: bold;
            }
        }

        .rank {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 15vw;
            font-size: 1.5vw;
            line-height: 2.5vw;
            font-weight: bold;
        }
    }
}

.page {
    min-height: 80vh;
    margin-bottom: 2vh;
    padding-bottom: 2vh;

    .web-version {
        .bs-centering {
            height: 27vh;
        }
    }

    .progression {
        width: 10vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .span {
            width: fit-content;
            font-size: 2.2vh;
            font-weight: bold;
        }

        .circle {
            width: fit-content;
            .progress {
                width: 2.4vh;
                height: 2.4vh;
                border-radius: 100%;
                padding: 0;
                margin: 0;
            }
        }

        .dark-red {
            background-color: #c9281b;
        }

        .green {
            background-color: #1bc97f;
        }

        .orange {
            background-color: #fdad15;
        }
    }
}

.approved-popup {
    .saved-popup {
        padding-top: 3vh;
        position: absolute;
        top: 50vh;
        min-height: 50vh;
        width: 62vw;
        border: none;
        color: black;
        border-radius: 15px;
        font-size: 2vw;
        line-height: 3vw;

        .student {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                font: normal normal bold 1.5vw/3vw Rubik;
                font-size: 1.5vw;
                line-height: 3vw;
                font-weight: bold;
            }

            .image {
                height: 1.75vw;
                margin: 2vh 0.75vh;
            }
        }
    }
}
