.error404 {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .errorImg {
        height: 15vh;
        width: 45vh;
        max-width: 70vw;
        max-height: 24vw;
    }

    .noPage {
        color: var(--custom-footer-blue);
        font-size: 5vh;
        line-height: 7vh;
        font-weight: bold;
        text-align: center;
        max-width: 70vw;
    }

    .back-button {
        background-color: #4aa2df;
        border: none;
        color: white;
        padding: 1.5vh;
        border-radius: 2.5vh;
        display: flex;
        align-items: center;
        font-size: 2vh;
        cursor: pointer;
        box-shadow: 1px 1px 7px 2px #3b3b3b27;

        &--arrow {
            cursor: pointer;
            height: 1.5vh;
            margin-left: 0.8vw;
        }

        .text {
            cursor: pointer;
        }

        &:focus {
            outline: solid black 1px;
        }
    }
}

.page404 {
    .imgtop {
        position: absolute;
        top: 5vh;
    }

    .imgbottom {
        position: absolute;
        bottom: 7.5vh;
    }
}

.noPageTitle {
    background-color: var(--custom-dark-blue);
    color: white;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .titlet {
        margin-left: 1vw;
        font-size: 2.7vh;
        line-height: 10vh;
        font-weight: 600;
        white-space: nowrap;
    }

    img {
        height: 4vh;
    }
}
