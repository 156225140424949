.rotateDevice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 10vh;
    line-height: 11vh;
    font-weight: 300;
    color: #76838f;
    background-color: #f6f6f6;
    height: 100vh;
}
