@import "changing-sizes.scss";

.age-recommended-container {
    display: flex;
    width: 90vw;
    overflow: auto;
    overflow: overlay;
    padding-bottom: 3vh;
    margin-top: 4vh;
    margin-right: 4vw;
    column-gap: 4vh;

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        display: none;
    }
}
.gray-background {
    background-color: #f1f1f1;
    cursor: pointer;
    box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

    width: 50vw;
    height: 50vw;
    // margin-left: 4vh;

    border-radius: 15px 15px 0 0;

    //center the div in the container
    display: flex;
    justify-content: center;
    align-items: center;
    &.no-curve{
    border-radius: 15px;
    }
}



.grid-inner-container{
    width: 90%;
    height: 90%;

    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);


    .book-image {
        width: 80%;
        height: 80%;
    
        cursor: pointer;
        border-radius: 7px;
        box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);
        }
}
.center-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}


.recommended-for-footer {
    background-color: white;
    width: 50vw;
    height: 16vw;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 6px 10px -5px rgba(0, 0, 0, 0.2);
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-indent: 10%;

    .name {
        font-weight: 900;
        font-size: 2.2vh;
    }

    .number {
        font-size: 2vh;
    }
}

.web-version {
    .age-recommended-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: unset;
        margin-right: 0;
    }

    .gray-background {
        width: 17vw;
        height: 18vw;
    }

    .recommended-for-footer {
        width: 17vw;
        height: 8vh;
        box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);
    }
}
