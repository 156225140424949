.popup-background {
    position: fixed !important;
    height: 100vh;
    width: 100vw;
    top: 0vh;
    right: 0;
    z-index: 12;
    background-color: #bcbccb5c;
}

.saved-popup {
    text-align: center;
    position: fixed;
    border-radius: 25px;
    color: var(--custom-dark-blue);
    background-color: white;
    border: 5px solid var(--custom-dark-blue);
    width: 80vw;
    transform: translate(-50%, -50%);
    height: auto;
    z-index: 10;
    font-size: 40px;
    left: 50%;
}

.generic {
    .saved-popup {
        top: 45%;
        left: 50%;
    }

    .bounce-7 {
        position: fixed;
        bottom: 55%;
        left: 50%;
    }
}

.bp-popup {
    .popup-background {
        top: 50vh;
        opacity: 0;
    }
}

.MuiBackdrop-root {
    height: 100vh !important;
    width: 100vw !important;
}

.MuiDialog-paperScrollPaper {
    .saved-popup {
        width: 80vw;
        top: 50%;
        font-size: 30px;

        .MuiDialogContent-root {
            padding: 0 2.5vw 4vh 2.5vw;
        }
    }

    .bounce-7 {
        position: fixed;
        top: 50%;
        left: 50%;
    }

    .web-version {
        .saved-popup {
            width: 35vw;
        }
    }
}

.close-popup {
    text-align: right;
    margin-right: 2.5vw;
    background-color: transparent;
    border: none;
    padding: 0;
    display: block;
    border-radius: 15px;
    background-color: inherit;
}

.popup-icon {
    height: 3vh;
    margin-top: 3vh;
    margin-right: 1vh;
    cursor: pointer;
}

.popup-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh 0.5vw 0vh 0.5vw;
}

.popup-img {
    height: 15vh;
    margin-bottom: 1vh;
}

.popup-button {
    background-color: var(--custom-dark-blue);
    color: white;
    width: auto;
    margin: 1vh;
    border-radius: 20px;
    padding: 2vh;
    font-size: 18px;
}

.popup-buttons {
    margin: 1vh;
    display: flex;
    margin-bottom: 2vh;
}

@media screen and (min-width: 1000px) {
    .saved-popup {
        width: 450px;
        padding: 2vh;
        padding-top: 0vh;
    }

    .close-popup {
        margin-right: 1vw;
        width: fit-content;
        color: inherit;
        background-color: transparent
    }

    .popup-img {
        margin-bottom: 2vh;
    }

    .popup-icon {
        cursor: pointer;
    }

    .popup-button {
        cursor: pointer;
    }
}

.bp-thanks {
    margin-top: -2vh;
    // font-weight: bold;
}

.bp-text {
    font-family: Rubik;
    font-weight: lighter;
    margin-bottom: 5vh;
}

.body {
    z-index: 1000000;
}
