.loading-circles-container {
    width: 7vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.big-player-dots {
    margin-top: 1vh;
    margin-bottom: 0.2vh;
}

@mixin circle {
    width: 0.85vw;
    height: 0.85vw;
    border-radius: 50%;
    background-color: grey;
    animation-name: jump;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-direction: alternate;
}

@keyframes jump {
    from {
        transform: translateY(25%);
    }
    to {
        transform: translateY(-25%);
    }
}

.circle-1 {
    @include circle;
}
.circle-2 {
    @include circle;
    animation-delay: 0.4s;
}
.circle-3 {
    @include circle;
    animation-delay: 0.8s;
}

@media (max-width: 768px) {
    .loading-circles-container {
        width: 25vw;
       
    }

    .big-player-dots{
        margin-bottom: 2vh;
    }

    .circle-1,
    .circle-2,
    .circle-3 {
        height: 2.5vw;
        width: 2.5vw;
    }
}
