@import "changing-sizes.scss";

.second-header {
    .header {
        height: 12vh;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-width: 100%;
        border-bottom: 1px solid #9e9c9c;
        padding-right: 7.5vw;

        .title {
            color: black;
            font-weight: bolder;
            font-size: 5vh;
        }

        .back-button{
            background-color: unset;
            height: 3vh;
            padding-left: 2vw;
        }

        .search {
            width: 20vw;
            height: 6vh;
            margin-left: 15vw;
            display: flex;
            align-items: center;
            background: #f7f7f7 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: vh(5);
            border: 1px solid black;

            #searchInput {
                font-size: 2vh;
            }

            #searchInput::-webkit-input-placeholder {
                font-size: vh(2);
            }

            #searchButton {
                margin-right: 1vh;
            }

            img {
                height: 4vh;
            }

            .categories {
                margin: 0;
            }

            #clearButton {
                height: 3vh;
                margin-right: 0;
                vertical-align: middle;
                cursor: pointer;
            }

            .results-number {
                height: auto;
                font-size: 1.5vh;
                padding-right: 2vh;
                padding-top: 0;
                width: 16vh;
            }
        }
    }

    .second-header-categories {
        display: flex;
        flex-direction: row;
        justify-content: right;
        box-shadow: 0px 3px 6px #dad6d6;
        padding-right: 7.5vw;
        height: vh(10);
        align-items: center;

        .second-header-category {
            background: none;
            border: none;
            border-radius: vh(5);
            font-size: vh(2.3);
            margin-left: 1vw;
            padding: 0.8vw 1.3vw;
            font-family: Rubik;
            background-color: #e0e0e0b7;
            box-shadow: 0px 3px 6px #dad6d6;
            min-width: 8vw;
            &:hover {
                cursor: pointer;
                background-color: #f8a7a7c4;
              }
        }
        .pressed{
            background-color: #ee5c5c;
            &:hover {
                cursor: pointer;
                background-color: #ee5c5c;
              }
        }
        
    }
}

@media only screen and (max-width: 1024px) {
    .second-header {
        .mobile-search {
            display: flex;
            align-items: center;
            background: #f7f7f7 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 40px;
            width: 90vw;
            border: 1px solid black;
            margin-right: 5%;
            margin-left: 6%;
            height: 7vh;
        }

        .second-header-categories {
            height: vh(13);
            justify-content: center;
            padding-right: 3vw;
            padding-left: 3vw;
            flex-wrap: wrap;
            width: 100vw;

            .second-header-category {
                width: 31%;
                font-size: 2vh;
                margin-right: 0.6vw;
                // padding-right: 1vw;
                padding: 1vw 1.5vw;
                min-width: 17vw;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .second-header {
        .second-header-categories {
            height: fit-content;

            .second-header-category {
                margin-bottom: vh(1.5);
            }
        }
    }
}
