#book-reviews {
    padding: 0vh 4vw 2vh 0vh;
    font-size: 3.5vh;
    font-family: Rubik;
    font-weight: bolder;
}

.phone-version {
    #book-reviews {
        font-size: 2.75vh;
    }
}

.stars {
    direction: ltr;
}

.star {
    padding-left: 5px;
    height: 3vh;
}

.ratings {
    display: flex;
    flex-direction: column;
    padding-right: 4vw;
}

.no-rating {
    padding-bottom: 6vh;
    color: #707070;
    font-size: 2vh;
}

.all-ratings {
    display: flex;
    width: 90vw;
    max-width: 325px;
    justify-content: space-between;
    padding: 1.5vh 0vh;

    .star {
        height: 2.5vh;
    }
}

.rating-profile {
    display: flex;
    flex-direction: row;
}

.rating-name {
    font-weight: normal;
    margin: 1vh 0vh;
    font-size: 3vh;
}

.rating-types {
    font-weight: normal;
    font-size: 2.5vh;
}

.rating-component {
    font-family: Rubik;
    color: black;
    margin-bottom: 2vh;
}

.review-headline {
    font-size: 3vh;
    margin: 1vh 0vh;
    font-weight: bolder;
}

.review {
    font-weight: normal;
    font-size: 14px;
    color: #707070
}

.web-ratings {
    display: flex;
    justify-content: space-between;
    margin-left: 10vw;
    max-width: 700px;

    .rating-name {
        margin-right: 1.75vw;
    }

    .rating-types {
        margin-right: 3.5vw;
    }

    .rating-icon {
        width: 1.75vw;
        min-width: 21px;
    }

    .rating-text {
        width: 20vw;
        margin-right: 5vw;
    }
}

.phone-ratings {
    margin-right: 1vw;

    .review-headline {
        font-size: 2.5vh;
        margin-top: 3vh;
    }

    .review {
        padding-top: 0vh;
        font-size: 2vh;
        padding-bottom: 1vh;
    }

    .rating-name {
        font-size: 2vh;
        padding-bottom: 1vh;
    }

    .all-ratings {
        padding-top: 0.75vh;
    }
}

@media only screen and (min-height: 650px) {

    #book-reviews {
        font-size: 3vh;
    }

    .rating-name {
        font-size: 2.75vh;
    }

    .review-headline {
        font-size: 2.75vh;
    }

    .rating-types {
        font-size: 2.25vh;
    }
}

@media only screen and (min-height: 700px) {

    #book-reviews {
        font-size: 2.75vh;
    }

    .rating-name {
        font-size: 2.5vh;
    }

    .review-headline {
        font-size: 2.5vh;
    }

    .rating-types {
        font-size: 2vh;
    }
}