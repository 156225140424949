@import "changing-sizes.scss";

.skeleton-box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4vh;
    margin-top: 2vh;
}

.categories-page {
    margin-bottom: 12vh;
    width: 84.3vw;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // margin-bottom: 8.3vh;
}

.responsive-categories {
    display: flex;
    justify-content: center;
}

.category {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 15px;
    text-align: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 2vw /*1vw 2vw 1vw*/;
    font-size: vh(2.4);
    font-weight: lighter;
    outline: none;
    cursor: pointer;

    &:focus {
        outline: solid 1px;
    }
}

.displayedBooks {
    display: flex;
    justify-content: space-between;
    margin-left: 10vw;
    width: fit-content;
}

.recs {
    font-family: Rubik;
    font-weight: bold;
    text-align: right;
    letter-spacing: 0px;
    color: #1f1e1e;
    margin-bottom: 2vh;
}

.newBooks {
    margin-top: 2vh;
    margin-bottom: 3vh;
    // background-color: violet;

    .scroolbar {
        display: flex;
        justify-content: space-around;
    }
}

.scrollbar {
    width: 90vw;
    overflow: auto;
    overflow: overlay;
    white-space: nowrap;
    margin-top: 1vh;
    margin-right: 1vh;
}

.scroll {
    display: inline-block;
    font-size: 12px;
    margin: vh(1);
    width: vh(20);
    height: vh(28);
    overflow: hidden;
    vertical-align: middle;

    .grayBook {
        background-color: #eeedec;
        box-shadow: none;
    }
}

.short {
    height: vh(24);
}

.category-container {
    display: flex;
    width: fit-content;
    flex-direction: column;
}

.categoryInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    background-color: unset;
}

.categoryAuthor {
    font-weight: normal;
    padding-right: 0.5vh;
    font-size: vh(1.75);
    // height: vh(1.5);
}

.web-version {
    // .categories-page {
    //     // width: 75vw;
    //     // padding-right: 3vh;
    // }

    .categories {
        border-bottom: 0.1px solid #707070;
        justify-content: right;
        box-shadow: 0px 4px 6px #dad6d6;
        padding-right: 23.4vh;
        padding-left: 23.4vh;
        flex-wrap: wrap;
    }

    .category {
        font-size: 2vh;
        margin: 2vh 1vh;
        height: 4vh;
        display: flex;
        align-items: center;
        border-radius: 11px;
    }

    .scroll {
        width: 16vw;
        height: 21vw;
        margin-left: 3vw;
    }

    .short {
        height: 17vw;
    }

    .scrollbar {
        overflow-x: hidden;
        width: 100%;
    }

    .categoryInfo {
        height: auto;
    }

    .categoryAuthor {
        padding-right: 1vh;
        font-size: 2vh;
    }

    .displayedBooks {
        padding-top: 3vh;
        // margin-right: 0;
    }

    @media only screen and (max-width: 1175px) {
        // .header {
        //     // margin-right: 6.75vw;
        //     // padding-right: 0 !important;
        // }

        .categories-page {
            width: 85vw;
            padding-right: 0vh;
        }

        .scrollbar {
            margin-left: 7vw;
            margin-right: 7vw;
        }

        .scroll {
            height: 24vw;
        }

        .short {
            height: 19vw;
        }

        .categories {
            padding: 0vh 7vw;
            align-items: center;
        }

        .recs {
            width: 85vw;
            margin-right: 6.75vw;
        }
    }

    @media only screen and (max-width: 920px) {
        .header {
            // margin-right: 4.75vw;
            // padding-right: 0 !important;
            .search {
                margin-left: 8vh !important;
            }
        }

        .categories {
            padding: 0vh;
            justify-content: center;
        }

        .categories-page {
            padding-right: 0vh;
        }

        .scrollbar {
            margin-left: 5vw;
            margin-right: 5vw;
        }

        .library-img {
            height: 17.5vw;
            width: 17.5vw;
        }

        .scroll {
            height: 26vw;
            width: 20vw;
        }

        .short {
            height: 21vw;
        }

        .recs {
            margin-right: 4.75vw;
        }
    }
}
@media only screen and (max-width: 768px) {
    .categories-page {
        width: 95vw;
    }

    .header {
        // margin-right: 4.75vw;
        // padding-right: 0 !important;
        .search {
            margin-left: 8vh !important;
        }
    }

    .categories {
        padding: 0vh 5vw;
        justify-content: center;
    }

    .category {
        margin-top: 1vh;
    }

    .categories-page {
        padding-right: 0vh;
    }

    .scrollbar {
        margin-left: 3vw;
        margin-right: 5vw;
    }

    .library-img {
        height: 18.5vw;
        width: 18.5vw;
    }

    .scroll {
        height: 26vw;
        width: 20vw;
    }

    .short {
        height: 21vw;
    }

    .img {
        font-size: 2.75vh;
    }

    .scroll {
        height: 28vw;
    }

    .short {
        height: 21vw;
    }
}
