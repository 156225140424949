@import "changing-sizes.scss";

.log-out-container {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;

    outline: inherit;
    cursor: pointer; // remove all button css

    // push to the left
    display: flex;
    align-items: center;
    width: 90%;
    margin-bottom: vh(2);
    margin-top: vh(-10);

    .log-out-icon {
        height: vh(2.7);
        cursor: pointer;
        margin-left: 1.5vw;
    }

    .log-out-button {
        font-size: vh(3.5);
        line-height: vh(8);
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        color: white;
        font-size: vh(2.5);
        background-color: transparent;
        letter-spacing: 0.35px;

        * {
            cursor: pointer;
        }
    }
}

.menu-log-out-container {
    background-color: var(--custom-footer-blue);
    color: inherit;
    border: none;
    padding-right: vh(4);
    padding-left: vh(4);
    font: inherit;
    outline: inherit;
    cursor: pointer; // remove all button css
    border-radius: vh(4);

    // push to the left
    order: 2;

    display: flex;
    align-items: center;
    gap: vh(0.7);
    .menu-log-out-icon {
        height: vh(2.4);
        cursor: pointer;
    }

    .menu-log-out-button {
        font-size: vh(3.5);
        line-height: vh(8);
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        color: white;
        font-size: vh(1.7);
        background-color: transparent;
        letter-spacing: 0.35px;
        font-size: vh(2.7);

        * {
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .log-out-container {
        margin-left: vh(8);
    }
}
