@import "changing-sizes.scss";

.container-slide {
    margin-right: 1vh;
    .container-title {
        margin-right: 0.3vh;
        margin-bottom: vh(1.66);
    }

    .flex-big {
        display: flex;
        // justify-content: space-between;
        gap: 3.8%;
        // height: vh(40.5);
        min-height: fit-content;
    }
    // .comp .down

    .responsive-box-button {
        background-color: unset;
        border: none;
    }
}
@media screen and (max-width: 768px) {
    .container-slide {
        .flex-big {
            justify-content: center;
            align-items: center;
            height: fit-content;
        }
        .grey-book {
            width: 50vw;
            height: 50vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 2vh;
        

            &:not(:first-child) {
                // hide the other grey background except the first one
                display: none;
            }
        }
    }
}
