* {
  cursor: default;
}

.rpcc-play-button {
  background-color: #28e1fe;
  outline: 0;
  box-shadow: inset 0 0 18px 4px #00000029;
  cursor: default;
}

.rpcc-play-icon {
  border-color: transparent transparent transparent var(--rpcc-play-icon-color, #fff);
  margin-left: calc(var(--rpcc-play-button-size) * 0.04166666667 + 4.5px);
  margin-top: 3px;
}

.rpcc-ring__played {
  opacity: 1;
  stroke: #ed7171;
}

.rpcc-ring__loaded {
  stroke: #ed7171;
  opacity: 0.4;
}

.rpcc-ring__duration {
  stroke: white;
}

.rpcc-ring-container {
  box-shadow: 0 0 16px 5px #00000029;
  border-radius: 50%;
}

.rpcc-player {
  --rpcc-size: 160px !important;
  --rpcc-progress-size: 9px !important;
}

@media (max-width: 411px) {
  .rpcc-player {
    --rpcc-size: 19.85vh !important; //19.85vh
    --rpcc-progress-size: 1.25vh !important; //1.25vh
  }

  .ios {
    .rpcc-player {
      --rpcc-size: 125px !important; //19.85vh
      --rpcc-progress-size: 6px !important; //1.25vh
    }
  }
}

.play-and-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .center_player {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 5% 0;
  }
}

.pink {
  color: black;
}

.goto {
  display: flex;
  bottom: 10%;
  align-items: center;
  justify-content: space-evenly;
  height: 30vh;
  width: 100%;
}

.seek {
  width: 12vw;
  max-width: 53px;
}

.redBox {
  width: 100%;
  height: 55vh;
  margin: auto;
}

.book {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 3.5vw;
  height: 40vh;
  width: 70%;
  z-index: 11;
  justify-content: space-between;
}

@keyframes spotifyAnimation {
  from {
    text-indent: 100%;
  }
  to {
    text-indent: -200%;
  }
}

.spotifyAnimation {
  text-indent: 100%;
  animation-name: spotifyAnimation;
  animation-duration: 10s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.bTitle {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  font-family: Rubik;
  font-size: 2.5vh;
  font-weight: bold;
  line-height: 3.2vh;
  width: 90%;
  margin: auto;
}

.bTitle1 {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: Rubik;
  font-size: 2.5vh;
  line-height: 3.2vh;
  width: 80%;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

div.moveTitle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 6vw;
  line-height: 10vw;
  letter-spacing: 0px;
  color: #ffffff;
}

.bAuthor {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;

  * {
    font-size: 5vw;
    line-height: 6vw;
  }
}

.bTime {
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;

  letter-spacing: 0px;
  color: #ffffff;
}

.book-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
}

.book-img1 {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29vh;
  height: 29vh;
  max-width: 200px;
  max-height: 200px;
  background: var(--custom-light-blue) 0% 0% no-repeat padding-box;
  border-radius: 1.5vh;
  box-shadow: 0 0 7px 2px #17171757;
  img {
    width: 100%;
    height: 100%;
  }
}

.arrow-button {
  position: absolute;
  top: 3vw;
  left: 2vw;
  width: fit-content;
  height: fit-content;
  background-color: #e56769;
  margin-right: 0.5vw;
  padding: 0;
  border: none;
  outline: none;

  &--img {
    width: 14vw;
    max-width: 50px;
  }

  &:focus {
    outline: solid 1px white;
  }
}

.close-player {
  position: absolute;
  top: 4.5vw;
  right: 2.5vw;
  width: fit-content;
  z-index: 5;
  padding: 0;
  background-color: #e56769;
  margin-right: 0.5vw;
  border: none;

  &--img {
    width: 5vw;
    max-width: 50px;
    z-index: 5;
  }

  &:focus {
    outline: solid 1px white;
  }
}

.speed {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  padding: 0;
  border: none;
}

.tinySpeed {
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
}

.number {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.5rem;
}

.chapter-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: inherit;
  border: none;
  outline: none;

  &:focus {
    outline: solid 1px;
  }
  // cursor: pointer;

  .chapter {
    margin-bottom: 0.6vh;
  }
}

.all {
  z-index: 6;
  position: absolute;
  top: 0;
  //bottom: 8vh;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.bar {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  top: 48vh;
  align-items: center;
}

.pre {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  max-width: 175px;
  p {
    font-size: 1.25em;
    font-weight: 300;
  }
}

.firstTime {
  position: absolute;
  top: 0;
  background-color: rgba($color: #fff, $alpha: 0.2);
  z-index: 9;
  width: 100vw;
  height: 100vh;
}

.loading {
  position: absolute;
  top: 54vh;
  bottom: 9.11vh;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingbig {
  position: absolute;
  top: /*10vh*/ 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.miniLoading {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60vh;
  z-index: 9;
  width: 19.85vh;
  height: 19.85vh;

  .loader-container {
    padding: 0;
  }
}
.noIconPlayer {
  .rpcc-play-icon {
    border-color: transparent transparent transparent #28e1fe !important;
  }
}
@media (max-width: 350px) and (max-width: 768px) {
  .rpcc-player {
    --rpcc-size: 18vh !important;
    --rpcc-progress-size: 1vh !important;
  }

  .ios {
    .rpcc-player {
      --rpcc-size: 70px !important;
      --rpcc-progress-size: 4px !important;
    }
  }

  .center_player {
    font-size: 1rem;
    line-height: 2rem;
  }

  .seek {
    width: 9vw;
    max-width: 40px;
  }

  .book {
    height: 35vh;
    top: 6vw;

    .book-img1 {
      max-width: 90px;
      max-height: 90px;
    }

    div.moveTitle {
      font-size: 5vw;
      line-height: 9vw;
      font-weight: bold;
    }

    .bAuthor {
      font-size: 4vw;
      line-height: 5vw;
    }

    .bTime {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 300;
    }
  }

  .bar {
    img {
      height: 5vw;
    }

    .chapter {
      height: 4vw;
    }

    .tinySpeed {
      font-size: 0.6rem;
      line-height: 0.75rem;
    }

    .number {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
}

.browser {
  .opacity {
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.9;
    z-index: 5;
  }

  .bigPage {
    position: absolute;
    top: 0;
    display: flex;
    background-color: rgba($color: #fff, $alpha: 0.5);
    justify-content: center;
    z-index: 5;
    width: 100vw;
    height: 100vh;
  }

  .all {
    max-width: 57vh;
    max-height: 90vh;
    margin-top: 4vh;
    box-shadow: 0 0 2.4vh 0 #17171757;
    // opacity: 1;
  }

  .goto {
    width: 80%;
    margin: auto;
    margin-top: 1.6vh;
    height: 32.15;
    bottom: 0;
  }

  .rpcc-player {
    --rpcc-size: 18.5vh !important; // 18.5vh
    --rpcc-progress-size: 0.96vh !important; // 0.96vh
    margin: 1.6vh 0;
  }

  .seek {
    width: 4.82vh;
    cursor: pointer;
  }

  .bAuthor {
    text-align: center;
    font-size: 2.09vh;
    line-height: 2.4vh;
  }

  .bTime {
    text-align: center;
    font-size: 1.45vh;
    line-height: 1.9vh;
    font-weight: 300;
  }

  .book {
    top: 7vh;
    height: 37%;
    align-items: center;
  }

  .book-img1 {
    width: 20.89vh;
    height: 20.89vh;
    // padding: 5%;
    border-radius: 0.8vh;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .redBox {
    height: 56.25vh;
  }

  .arrow-button {
    top: 2vh;
    left: 2vh;
    margin-right: 0.5vw;

    &--img {
      width: 5vh;
    }
    // cursor: pointer;
  }

  .close-player {
    top: 3vh;
    right: 2vh;
    margin-right: 0.5vw;

    &--img {
      width: 3vh;
      cursor: pointer;
      z-index: 10;
    }
  }

  .pre {
    max-width: 19.29vh;
    p {
      font-size: 1em;
      font-weight: 300;
    }
    img {
      height: 2.89vh;
      // cursor: pointer;
    }
  }

  div.moveTitle {
    justify-content: right;
  }

  .bar {
    top: 54vh;
  }

  .tinySpeed {
    font-size: 1.45vh;
    line-height: 1.6vh;
    // cursor: pointer;
  }

  .number {
    font-size: 2.41vh;
    line-height: 2.57vh;
    cursor: pointer;
  }

  .center_player {
    font-size: 2.1vh;
    line-height: 2.41vh;
  }

  .chapter {
    height: 2vh;
    // cursor: pointer;
  }

  .speed,
  .rpcc-play-icon,
  .rpcc-play-button {
    cursor: pointer;
  }

  .rpcc-play-button:focus {
    outline: solid 1px;
  }

  .loadingbig {
    position: absolute;
    top: /*10vh*/ 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .firstTime {
    z-index: 6;
    max-width: 57vh;
    display: flex;
    justify-content: center;
    max-height: 90vh;
    margin: auto;
    margin-top: 4vh;
    justify-content: center;
  }

  .loading {
    position: absolute;
    top: 57.9vh;
    height: 30.2vh;
  }

  .miniLoading {
    top: 67.63vh;
    width: 18.5vh;
    height: 18.5vh;
  }
}

.ios.browser {
  .rpcc-player {
    --rpcc-size: 120px !important; // 18.5vh
    --rpcc-progress-size: 7px !important; // 0.96vh
    margin: 1.6vh 0;
  }
}

///
.scale-up-ver-bottom {
  -webkit-animation: scale-up-ver-bottom 0.4s ease-in reverse;
  animation: scale-up-ver-bottom 0.4s ease-in reverse;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-10-28 9:27:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */

@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

///
.scale-in-ver-bottom {
  -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/* ----------------------------------------------
 * Generated by Animista on 2020-10-27 16:43:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */

@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}
