* {
  box-sizing: border-box;
}

.list {
  background-color: #f1f1f1;
  padding: 5px 25px;
  opacity: 0.9;
  letter-spacing: 0.24px;
  color: #030303;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;
  overflow: auto;
}

.long-list{
  padding-bottom: 10vh;
}

hr {
  background-color: #d5d3d3;
}

.lt {
  display: flex;
  justify-content: space-between;
  padding: 2.5vh;
  background-color: #ffffff;
  opacity: 1;
  height: 8vh;
}

.x {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
}

.speedTitle {
  text-align: center;
  font-size: 3vh;
  font-weight: bold;
  line-height: 3vh;
  letter-spacing: 0px;
  color: #030303;
}

.speeds {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
}

.check {
  width: 20px;
  height: 20px;
}

.current {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.num {
  font-size: 2.5vh;
  line-height: 6vh;
  font-weight: normal;
  height: 6vh;
}

.bold {
  font-size: 2.5vh;
  line-height: 6vh;
  font-weight: bold;
  letter-spacing: 0.24px;
}

.browserBig {
  .bigPage {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .speeds {
    margin: auto;
    max-width: 57vh;
    max-height: 90vh;
    margin-top: 4vh;
  }

  .list {
    opacity: 1;
  }

  .speedTitle {
    font-weight: bold;
    font-size: 2.4vh;
    line-height: 2.89vh;
  }

  .num {
    font-size: 2vh;
    line-height: 5.22vh;
    height: 5.22vh;
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
    font-size: 2vh;
    line-height: 5.22vh;
    letter-spacing: 0.04vh;
  }

  .check {
    width: 2.25vh;
    height: 2.25vh;
    cursor: pointer;
  }

  .lt {
    padding: 2.089vh;
    height: 44px;
    border-bottom: 2.5px ridge #3a3a3a57;
    cursor: default;
  }

  .x {
    cursor: pointer;
    width: 1.93vh;
    height: 1.93vh;
  }

  .current {
    cursor: pointer;
  }

  .none {
    cursor: default;
  }
}

.speed-button,
.chapter-button {
  cursor: pointer;
  border-radius: 0;
  font-size: 2.25vh;
  display: inline-block;
  color: inherit;
  background-color: inherit;
  border: none;
  text-align: right;
}
