.choose {
    display: flex;
    height: 15vh;
    justify-content: space-between;
    align-items: center;
    padding: 0 12vw;
    border-bottom: 1px solid #707070;
    box-shadow: 0 1px 10px 0 #929292;

    .whichComp {
        width: 20vw;
        height: 5vh;

        .elements {
            display: flex;
            justify-content: space-between;

            .element {
                width: 9vw;
                font-size:.3vw ;
                line-height: 2.5vw ;
                font-weight:bold ;
                text-align: center;
                cursor: pointer;
            }
        }

        .pink-container {
            position: relative;
            transition: 0.5s;

            .shortpink {
                background-color: #eb6868;
                width: 9vw;
                height: 4px;
            }
        }
    }

    .search-container {
        .search {
            width: 20vw;
            height: 6vh;
            margin: 0;
            border: 1px solid black;
            border-radius: 15px;
            box-shadow: none;
            background: none;
        }

        #searchButton {
            margin: 0;
            height: 4vh;
        }

        #clearButton {
            min-width: 20px;
            cursor: pointer;
        }

        .noResult {
            position: absolute;
            width: 30vw;
            overflow: hidden;
            text-align: center;
            color: #0c3e89;
            font-size: 2.5vh;
            line-height: 3vh;
            margin: 0.5vh 0;
        }
    }
}

.general-div-big {
    div {
        padding: 0.6vw;
    }
}

.general-div {
    div {
        padding: 5px;
    }
}
