@import "changing-sizes.scss";

.turn-up {
    transform: rotate(180deg);
}

.q-a-page {
    height: vh(100);
    // width: 100vw;
    // overflow: auto;
    // overflow: overlay;
    // direction: rtl;
    .q-a-title {
        display: flex;
        align-items: center;
        font-weight: bolder;
        font-size: 5.5vh;
        padding-right: 8%;
        height: 12vh;
        border-bottom: 1px solid #9e9c9c;
        font-family: Rubik;
    }

    .q-a-texts {
        padding-top: vh(4);
        padding-right: 8%;
        padding-left: 4%;
        display: flex;
        flex-direction: column;
        align-items: right;
        width: 90vw;

        .q-a-part {
            padding-bottom: vh(15);
            font-family: Rubik;

            .q-a-text-title {
                font-weight: bolder;
                font-size: 4.5vh;
                padding-bottom: vh(2.5);
                width: fit-content;
            }

            .q-a-red {
                background-color: #fb4e5d;
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .q-a-yellow {
                background-color: var(--custom-yellow);
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .q-a-blue {
                background: var(--custom-medium-blue);
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .q-a-first {
                border-top: 1px solid #c7c7c7;
            }

            .q-a-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-left: 1.5vw;
                padding-right: 0.5vw;
                border-bottom: 1px solid #c7c7c7;
                .q-a-question {
                    display: flex;
                    flex-direction: column;
                    height: fit-content;
                    width: 90%;
                    margin-top: vh(2);
                    padding-bottom: vh(2.5);
                    cursor: pointer;
                    .q-title {
                        font-size: 3.2vh;
                        margin-bottom: vh(2);
                        cursor: pointer;

                    }

                    .q-answer {
                        font-size: 2.8vh;
                        line-height: vh(4.5);
                        cursor: pointer;

                    }

                    .q-bold {
                        font-weight: 600;
                    }
                }
                .q-a-img {
                    cursor: pointer;
                    transition: 0.5s ease;
                    height: vh(6);
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .q-a-page {
        .q-a-title {
            display: none;
        }

        .q-a-texts {
            .q-a-part {
                padding-bottom: vh(8);
                .q-a-text {
                    align-items: start;
                    .q-a-question {
                        .q-title {
                            font-size: 2.8vh;
                        }
                    }
                }
            }
        }
    }
}
