@import "changing-sizes.scss";

.img {
  width: vh(20);
  height: vh(20);
  // padding: vh(2);
  font-size: vh(2);
  // background: var(--custom-light-blue) 0% 0% no-repeat padding-box;
  border-radius: 1.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: normal;
  cursor: pointer;
  box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

  span {
    cursor: pointer;
  }

  img {
    border-radius: 1.5vw;
    width: 100%;
    height: 100%;
  }
}

.book-image {
  display: flex;
  justify-content: center;
  align-items: center;

  .home-img {
    display: none;
  }
}

.library-img,
.catalog-img {
  width: vh(20);
  height: vh(20);
  box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }
}

.cover-img {
  width: vh(20);
  height: vh(20);
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.home-img {
  width: 13.8vw;
  height: 13.8vw;
  // max-height: 300px;
  border-radius: 7px;
  color: white;
  font-size: 3vh;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: vh(1.1);
  box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    // box-shadow: 0 9px 2px -5px rgba(0, 0, 0, 0.2);
  }
}

.bp-img {
  width: vh(27);
  height: vh(27);

  img {
    width: 98%;
    height: 98%;
    border-radius: 7px;
    box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);
  }
}

.underline-link {
  text-decoration: none;
  color: white;
  font-family: Rubik;
  font-weight: bold;
}

.title {
  height: vh(4);
}

.author {
  font-weight: normal;
  font-size: vh(2);
  padding-right: 1vw;
  margin: 0;
  width: vh(18);
  text-align: right;
  color: #707070;
}

.length {
  font-weight: normal;
  font-size: vh(1.5);
  padding: vh(1) 1vw vh(1) 0;
  margin: 0;
  width: 30vw;
  text-align: right;
  color: #707070;
}

.summary {
  font-weight: normal;
  font-size: vh(1.5);
  padding-right: 1vw;
  margin-bottom: vh(1);
  width: 48vw;
  height: vh(5);
  text-align: right;
  color: #707070;
  overflow: hidden;
}

.two-line-summary {
  height: vh(7);

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &::after {
    content: "...";
  }
}

.link {
  height: fit-content;
  padding: 0 1vw vh(0.5) 0;
  color: var(--custom-dark-blue);
  text-decoration: none;

  &:focus {
    text-decoration: underline;
  }
}

.lightblue-book {
  background-color: var(--custom-light-blue) !important;
  color:white !important;
}

.yellow-book {
  background-color: var(--custom-yellow) !important;
  color:white !important;
}

.pink-book {
  background-color: var(--custom-pink) !important;
  color:white !important;
}

.blue-book {
  background-color: var(--custom-medium-blue) !important;
  color:white !important;
}
.grey-book {
  background-color: var(--custom-light-grey);
  display: block;
  padding: 2%;
  color: black;
  font-size: 120%;
}

// .home-img {
//   width: 29.5vh;
//   height: 29.5vh;
//   // max-height: 300px;
//   border-radius: 7px;
//   color: white;
//   font: normal normal bold 3vh Rubik;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   margin-bottom: vh(1.1);
// }

.book-blue,
.yellow-book,
.lightblue-book,
.pink-book {
  color: white;
}
// .pink {
//   color: black;
// }

.web-version {
  .s-container {
    width: 90%;
  }
  .categoryTitle {
    font-size: vh(2.2);
    font-size: vh(2.2);
    font-weight: bold;
    margin-bottom: vh(0.4);
  }
  .categoryAuthor {
    // font: vh(1.94);
    font-size: vh(1.94);
    font-size: vh(1.94);
    font-weight: 300;
    color: #707070;
  }

  .img {
    // padding: 3vh;
    border-radius: 7px;
    font-size: 3vh;
  }

  .library-img {
    width: 15.5vw;
    height: 15.5vw;
    box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: 100%;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  .catalog-img {
    width: 30vh;
    height: 30vh;
    box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: 100%;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  .author {
    // padding-right: 1vh;
    padding-bottom: vh(2);
    padding-top: 0;
    padding-right: 0;
    width: auto;
    font-size: vh(2);
  }

  .length {
    padding: 3vh 1vh;
    padding-right: 0;
    font-size: vh(2);
  }

  .s-length {
    padding: vh(2) 0 0;
    font-size: vh(2);
    font-weight: normal;
    color: #707070;
  }

  .summary {
    padding-right: 0;
    font-size: vh(2);
    width: auto;
    height: vh(7);
  }

  .s-genRating {
    width: 35vh;
    display: flex;
    justify-content: space-between;
    color: #707070;
    align-items: center;
    font-size: 16px;
  }

  .s-genR {
    color: #707070;
    font-family: Rubik;
    font-weight: normal;
    font-size: 2.3vh;
    padding-left: 3vh;
  }

  .s-star {
    height: 3vh;
    padding-left: 3px;
  }

  .smaller {
    font-size: vh(2);
    width: 26vh;
    border-radius: 14px;
    height: 5vh;
  }

  .underline-link {
    font-size: 3vh;
  }

  @media only screen and (max-width: 950px) {
    .home-img {
      height: 17.5vw;
    }
  }
}

///////////////////////////////////////////////////////////////
//bs => bookshelf//
.center-it {
  width: 98vw;
  justify-content: space-evenly;
  padding-right: 0;
  padding-bottom: 2vh;
}

.last {
  margin-bottom: 0;
}

.bs-centering {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: vw(40);

  .bs-container {
    display: flex;
    flex-flow: row;
    height: vh(10);

    .bs-author {
      // width: vh(16);
      width: max-content;

      .title {
        font-size: vh(2);
        height: auto;
        width: 35vw;
        margin: 0;
        padding-bottom: 0.3vh;
        padding-right: 0;
      }

      .author {
        padding-right: 0;
      }
    }
  }
}

.bs-length {
  font-weight: normal;
  font-size: 1.5vh;
  color: #707070;
  margin-top: 2vh;
}

.bs-progress-bar {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .color {
    width: 100%;
  }
}

.phone-bs {
  .bs-centering {
    .bs-container {
      margin-top: 1vh;
    }
  }
}

///WEB VERSION
.web-version {
  .center-it {
    justify-content: space-between;
    align-items: center;
    width: 80%;
    overflow: visible;
    margin: auto;
  }

  .last {
    margin-bottom: 0;
  }

  .bs-centering {
    width: 17vw;
    margin-left: 22vw;
    margin-right: 1vw;
    height: 23vh;
    justify-content: space-between;

    .bs-container {
      height: 5vh;

      .bs-author {
        height: 3vh;

        .author {
          width: max-content;
          font-size: 2vh;
        }

        .title {
          height: 3vh;
          overflow: hidden;
          // width: max-content;
          font-size: 3vh;
        }
      }
    }
  }

  .bs-progress-bar {
    margin-top: 1vh;
  }

  .bs-length {
    font-size: 1.8vh;
    // width: 25vw;
    width: max-content;
  }

  .bs-buttons {
    margin-left: 10vh;

    .play-button-done {
      width: fit-content;
      background-color: inherit;
      border: none;
      padding: 0;
      border-radius: 100px;

      img {
        cursor: pointer;
        width: 7vw;
        min-width: 90px;
        max-width: 105px;
        margin-right: 8vh;
        border-radius: 100px;
      }
    }

    .play-button-progress {
      width: fit-content;
      background-color: inherit;
      border: none;
      padding: 0;
      border-radius: 100px;

      img {
        cursor: pointer;
        width: 7vw;
        min-width: 90px;
        max-width: 105px;
        margin-right: 8vh;
        border-radius: 100px;
      }
    }
  }
}

.bs-progress-download {
  display: flex;
  align-items: center;
}

.book-square {
  height: fit-content;
  width: min-content;
  border-radius: 1.22vh;

  &--button {
    border: none;
    background-color: inherit;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .book-square {
    height: vh(20);
    width: vh(20);
  }
}
