@import "../style/changing-sizes.scss";

.signup-page {
    height: vh(100);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .signup-component {
        flex-grow: 1;
        overflow-y: auto;
        padding-top: vh(8);
        padding-bottom: vh(6);
        padding-right: 18vw;
        padding-left: 10vw;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: fit-content;

        .signup-titles--container {
            margin-bottom: vh(4);

            .signup-title {
                font-size: vh(4);
            }
        }

        .signup-texts-pic {
            display: flex;
            flex-direction: row;
            width: 100%;

            .signup-texts {
                width: 52%;
                margin-left: 2vw;

                .first-container {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: vh(3);

                    .first-container-num {
                        background-color: #fb4e5d;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: vh(8);
                        width: vh(8);
                        border-radius: 50%;
                        font-size: vh(3);
                        color: white;
                        z-index: 2;
                    }

                    .first-container-text {
                        width: 90%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;
                        left: vh(4);
                        padding-right: vh(8);
                        padding-left: 1rem;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        background-color: #f4f4f6;
                        font-size: vh(2);
                        word-spacing: .8px;

                        .sign-up-link {
                            font-weight: 400;
                            text-decoration: underline;
                            color: var(--custom-pink);
                            cursor: pointer;
                            margin-right: 0.3vw;
                            margin-left: 0.3vw;
                        }

                        .iner-text {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            width: 97%;

                            .qr-code {
                                height: vh(11);
                            }
                        }

                        .litle-things {
                            font-size: vh(1.4);
                            margin-top: vh(1.5);
                        }
                    }

                    .signup-blue {
                        background: var(--custom-medium-blue);
                    }

                    .signup-yellow {
                        background-color: var(--custom-yellow);
                    }

                    .signup-green {
                        background-color: var(--custom-green);
                    }

                }

                .d-flex-column {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    font-weight: 500;
                    font-size: 0.9rem;
                    gap: 0.5rem;
                    margin-top: vh(4);

                    a {
                        cursor: pointer;
                    }
                }

                .signup-help {
                    text-align: center;


                    .help-text {
                        font-weight: 600;
                    }
                }
            }

            .signup-pic {
                height: vh(52);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .signup-page {
        overflow-y: auto;

        .signup-component {
            padding-top: vh(4);
            padding-right: 8vw;
            padding-left: 4vw;

            .signup-title {
                font-size: vh(3);
            }

            .signup-texts-pic {
                flex-direction: column;

                .signup-texts {
                    width: 100%;

                    .first-container {
                        .first-container-num {
                            height: vh(7);
                            width: vh(7);
                            font-size: vh(2.5);
                        }

                        .first-container-text {
                            width: 90%;
                            padding-right: vh(7);
                            padding-left: .5rem;

                            .sign-up-link {
                                margin-right: 1vw;
                            }

                            .inside-text {
                                margin-top: vh(0.5);
                            }
                        }
                    }

                    .signup-help {
                        .help-nums {
                            margin-top: vh(1.5);
                        }
                    }
                }
            }
        }

        .signup-pic {
            width: 100vw;
            height: vh(10);
            margin-top: 2px;
        }
    }
}