.basic-button,
.generic-button {
    cursor: pointer;
    background: none;
    border: none;
}

.generic-button {
    border-radius: 25px;
    font-size: 2.25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    background-color: var(--custom-pink);

    &.no-link:hover {
        transform: scale(1.05, 1.05);
        transition: 0.2s;
    }
}

.disabled {
    &.no-link:hover {
        transform: unset;
        transition: unset;
    }
}
