@import "changing-sizes.scss";

.bs {
  font-family: Rubik;
}

.header-title {
  background: var(--custom-dark-blue) 0% 0% no-repeat padding-box;
  font-family: Rubik;
  font-weight: bold;
  height: 8vh;
  width: 100vw;
  min-height: 8vh;
  display: flex;
  align-items: center;

  padding: 0 3vw;
  font-size: 3.5vh;
  height: 4vh;
  text-align: right;
  color: white;
}

.bs-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1vh;

  .bs-category {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 15px;
    text-align: center;
    padding: 1.25vh vw(3) 1.25vh vw(3);
    margin: vw(2);
    font-size: 5vw;
    line-height: 5vw;
    font-weight: lighter;
    font-size: vw(5);
    outline: none;
    white-space: nowrap;
  }
}

.bs-title {
  font-family: Rubik;
  font-weight: bold;
  font-size: 2vh;
  padding-top: 3vh;
  padding-right: 6.5vw;
  margin-bottom: 1vh;
}

.progress {
  padding-right: 0.5vh;

  .bsimg {
    width: 45vw;
    height: 45vw;
    font-size: 2.5vh;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5%;
      box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);
    }
  }

  .bs-download {
    margin-bottom: 5vh;
    width: 6vw;
  }
}

.web-progress-bar {
  display: flex;

  .progress-bar-book-length {
    display: flex;
    justify-content: flex-end;
    width: max-content;
  }

  .zip {
    display: flex;
    justify-content: flex-end;
  }
}

.done {
  padding-right: 0.5vh;
  padding-bottom: 0.5vh;
  padding-top: 1vh;

  .bsimg {
    width: 40vw;
    height: 40vw;
    font-size: 2vh;
    margin-left: 5vw;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5%;
      box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.2);
    }
  }

  .bs-download {
    width: 4vw;
  }

  .bs-title {
    padding-right: 6vw;
  }
}

.changing-category:hover {
  border: solid 1px black;
}

.align-items {
  display: flex;
}

#bold {
  background-color: #ee5c5c;
}

// style={{ height: "60vh", display: "flex", alignItems: "center",
//    justifyContent: "center", flexFlow: "column" }}
.bs-no-books {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 60vh;

  .no-books-text {
    font-weight: bold;
    text-align: center;
    font-size: vh(2.5);
    width: 80vw;
  }

  .no-books-link {
    color: var(--custom-dark-blue);
    text-decoration: underline;
    cursor: pointer;

    &:visited {
      color: indigo;
    }
  }

  .no-books-button {
    color: var(--custom-dark-blue);
    background-color: inherit;
    margin-right: 3px;
    border: none;

    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    font-size: 2.5vh;
    font-family: Rubik;
    cursor: pointer;
  }
}

.book-and-trash-container {
  display: flex;

  

  .bookshelf-book {
    position: relative;
  }
  .book-shelf-trash {
    height: 2.5vh;
  }
  .icon-button-mui {
    background-color: white;
    z-index: 2;
    position: absolute;
    margin-right: 8%;
    margin-top: 2vh;
    height: 4vh;
    width: 4vh;
  }
}

.the-popup {
  display: flex;
  flex-direction: column;

  .bookshelf-popup-title {
    font-size: 28px;
    line-height:28px ;
    font-weight:bold ;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 6vh;
    height: 60%;
  }
  .bookshelf-popup-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .bookshelf-popup-button {
      width: 35%;
      height: 8vh;
      font-size:23px ;
      line-height: 26px;
      font-weight:bold ;
    }
  }
}

///WEB VERSION
.web-version {
  .header-title {
    background-color: inherit;
    color: black;
    font-weight: bolder;
    font-size: 5.5vh;
    height: 12vh;
    margin-right: 0vh;
    padding-right: 8vw;
    border-bottom: 1px solid #9e9c9c;
  }

  .bs-title {
    margin-right: 7.8vw;
    padding-right: 0;
    padding-top: 5vh;
    font-size: 3.5vh;
    margin-bottom: 2vh;
  }

  .bsimg {
    width: 16vw;
    height: 16vw;
    border-radius: 7px;
    font-size: 3vh;
    margin-left: 3vh;
    min-width: 150px;
    min-height: 150px;
    max-width: 225px;
    max-height: 225px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 3%;
      cursor: pointer;
    }
  }

  

  .second-header-categories {
    display: flex;
    flex-direction: row;
    justify-content: right;
    box-shadow: 0px 3px 6px #dad6d6;
    padding-right: 7vw;
    height: vh(10);
    align-items: center;

    .second-header-category {
      background: none;
      border: none;
      border-radius: vh(5);
      font-size: vh(2.3);
      margin-right: 1vw;
      padding: 0.8vw 1.3vw;
      font-family: Rubik;
      background-color: var(--custom-light-grey);
      box-shadow: 0px 3px 6px #dad6d6;
      min-width: 8vw;
      &:hover {
        cursor: pointer;
        background-color: #f8a7a7c4;
      }
    }
  }

  .bs-summary {
    font-family: Rubik;
    font-weight: lighter;
    font-size: 2vh;
    color: #707070;
    width: 36vw;
    max-width: 36vw;
    // height: 9vh;
    margin-top: 1vh;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &::after {
      content: "...";
    }
  }

  .progress {
    box-sizing: content-box;
    margin-right: 13vw;
    margin-top: vh;
    background-color: #f5f2f2;
    border-radius: 15px;
    width: 70vw;
    padding: 8vh 10vh 8vh 7vh;

    .no-books {
      font-size: 3.5vh;
    }

    .container {
      padding: 0;
    }

    .center-it {
      width: 70vw;
      margin-bottom: 4vh;
    }

    .last {
      margin-bottom: 0;
    }
  }

  .done {
    width: 100vw;

    .bsimg {
      width: 16vw;
      height: 16vw;
      min-width: 150px;
      min-height: 150px;
      max-width: 225px;
      max-height: 225px;
    }

    .container {
      height: 40vh;
      padding-right: 13vw;
    }

    .bs-centering {
      margin-right: 6vh;
    }

    .bs-length {
      font-size: 2.1vh;
    }

    .bs-download {
      width: 3vh;
    }
  }

  .book-and-trash-container {
    align-items: center;

    .book-shelf-trash {
      background-color: unset;
      border-radius: unset;
    }
    .icon-button-mui {
      margin-right: 7vh;
      border: 1px solid #888888;
      height: 5vh;
      width: 5vh;
    }
  }

  .bs-teacher {
    align-items: flex-start;
    height: 16vw;
    min-height: 150px;
    max-height: 225px;

    .bs-centering {
      height: fit-content;

      .bs-container {
        .bs-author {
          .author {
            font-size: 1.8vh;
          }
        }
      }

      .bs-summary {
        margin-top: 3vh;
        font-size: 2vh;
        height: fit-content;
        min-width: 340px;

        .summary-header {
          font-size: 2.2vh;
        }
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .progress {
      padding: 8vh 7vh;
    }

    .no-books-text {
      font-size: vh(3);
    }
  }

  @media only screen and (max-width: 1160px) {
    .progress {
      width: 75vw;
      margin-right: 10vw;
      padding: 8vh 6vh;

      .center-it {
        width: 75vw;
      }

      .last {
        margin-bottom: 0;
      }
    }

    .bsimg {
      width: 18vw;
      height: 18vw;
    }

    .bs-title {
      padding-right: 10vw;
    }

    .bs-categories {
      padding-right: 9.5vw;
    }

    .done {
      .container {
        padding-right: 10vw;
      }

      .bs-centering {
        margin-right: 2vw;
      }

      .bsimg {
        width: 18vw;
        height: 18vw;
      }
    }

    .bs-teacher {
      align-items: flex-start;
      height: 18vw;

      .bs-centering {
        height: fit-content;

        .bs-summary {
          width: 38vw;
          font-size: 1.8vh;

          .summary-header {
            font-size: 2vh;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1015px) {
    .bsimg {
      height: 19vw;
      width: 19vw;
    }

    .bs-centering {
      margin-left: 12vw;
    }

    .done {
      .bsimg {
        height: 19vw;
        width: 19vw;
      }

      .container {
        height: 37vh;
      }

      .bs-centering {
        margin-left: 22vw;
      }
    }

    .bs-buttons {
      margin-left: 0vw;
      width: 24vh;

      .play-button-progress {
        width: fit-content;
        background-color: inherit;
        border: none;
        padding: 0;
        border-radius: 100px;
        //   width: 24vh;
        // margin-right: 9vw;
        img {
          width: 7vw;
        }
      }
    }

    .bs-teacher {
      align-items: flex-start;
      height: 19vw;

      .bs-centering {
        height: fit-content;

        .bs-summary {
          width: 42vw;
        }
      }
    }
  }

  @media only screen and (max-width: 930px) {
    .bsimg {
      height: 20vw;
      width: 20vw;
    }

    .done {
      .bsimg {
        height: 20vw;
        width: 20vw;
      }
    }

    .no-books-text {
      font-size: vh(2.5);
    }

    .bs-teacher {
      align-items: flex-start;
      height: 20vw;

      .bs-centering {
        height: fit-content;

        .bs-summary {
          width: 46vw;
        }
      }
    }
  }

  @media only screen and (max-width: 880px) {
    .progress {
      margin-right: 8vw;
    }

    .bs-title {
      padding-right: 8vw;
    }

    .bs-categories {
      padding-right: 7.5vw;
    }

    .bs-centering {
      height: auto;
      margin-left: 15vw;

      .bs-container {
        .bs-author {
          .title {
            font-size: 2.5vh;
            // width: max-content;
          }

          .author {
            padding-bottom: 3vh;
            width: max-content;
          }
        }
      }
    }

    .bsimg {
      height: 21vw;
      width: 21vw;
    }

    .align-items {
      margin-left: 10vw;
    }

    .bs-buttons {
      .play-button-progress {
        margin-right: 3vw;
      }
    }

    .done {
      .bsimg {
        height: 21vw;
        width: 21vw;
      }

      .container {
        height: 35vh;
        padding-right: 8vw;
      }

      .bs-centering {
        margin-left: 20vw;
      }
    }

    .bs-teacher {
      align-items: flex-start;
      height: 21vw;

      .bs-centering {
        height: fit-content;

        .bs-container {
          .bs-author {
            .author {
              padding-bottom: 1vh;
            }
          }
        }

        .bs-summary {
          width: 58vw;
          font-size: 1.6vh;
          margin-top: 2vh;

          .summary-header {
            font-size: 1.8vh;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 805px) {
    .bsimg {
      height: 22vw;
      width: 22vw;
    }

    .bs-buttons {
      .play-button-progress {
        //   width: 20vh;
        margin-right: 2vw;
      }
    }

    .done {
      .container {
        height: 30vh;
        margin-top: 1vh;
      }

      .bsimg {
        height: 22vw;
        width: 22vw;
      }
    }

    .progress {
      width: 80vw;
      padding: 8vh 6vh 8vh 0vh;

      .center-it {
        width: 80vw;
      }

      .last {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 730px) {
    .bsimg {
      height: 23vw;
      width: 23vw;
    }

    .align-items {
      margin-left: 20vw;
    }

    .done {
      .bsimg {
        height: 23vw;
        width: 23vw;
      }
    }

    .bs-buttons {
      .play-button-progress {
        //   width: 22vh;
        margin-right: 0vw;
      }
    }
  }

  @media only screen and (min-height: 650px) {
    .bs-centering {
      .bs-container {
        .bs-author {
          .title {
            font-size: 2.75vh;
          }

          .author {
            font-size: 1.75vh;
          }
        }
      }
    }

    .bs-summary {
      font-size: 1.75vh;
    }

    .bs-length {
      font-size: 1.65vh;
    }

    .done {
      .bs-length {
        font-size: 1.9vh;
      }
    }
  }

  @media only screen and (min-height: 700px) {
    .bs-centering {
      .bs-container {
        .bs-author {
          .title {
            font-size: 2.6vh;
          }

          .author {
            font-size: 1.6vh;
          }
        }
      }
    }

    .bs-summary {
      font-size: 1.6vh;
    }

    .bs-length {
      font-size: 1.5vh;
    }

    .done {
      .bs-length {
        font-size: 1.75vh;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .second-header-categories {
    display: flex;
    align-items: center;
    height: vh(12);
    justify-content: center;
    padding-right: 3vw;
    padding-left: 3vw;
    flex-wrap: wrap;
    width: 100vw;

    .second-header-category {
      background: none;
      border: none;
      border-radius: vh(5);
      font-size: vh(2.3);
      font-family: Rubik;
      background-color: #e0e0e0b7;
      box-shadow: 0px 3px 6px #dad6d6;
      margin-right: 2.5vw;
      padding: 1vw 1.5vw;
      min-width: 17vw;
    }
  }
}

@media only screen and (max-width: 620px) {
  .second-header-categories {
    .second-header-category {
      font-size: vh(1.8);
    }
  }
}
