@import "changing-sizes.scss";

.contact-us {
	// width: vh(82);
	height: 100%;

	.pre-form {
		width: 100vw;
		.title {
			display: flex;
			align-items: center;
			font-weight: bolder;
			font-size: 5.5vh;
			padding-right: 8%;
			height: 12vh;
			border-bottom: 1px solid #9e9c9c;
			font-family: Rubik;
		}

		.description {
			display: inline-block;
			margin-bottom: vh(6.4);
			color: var(--custom-dark-blue);
			font-size: 1.5em;
			line-height: 1.8em;
			font-weight: bold;
			margin-right: 8vw;
			margin-top: vh(1);
		}
	}
	.form {
		margin-right: 15.31vw;
		width: 47vw;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.input-container {
			display: flex;
			flex-direction: column;
			.input {
				background: #bcbccb2b 0% 0% no-repeat padding-box;
				border: 0.2px solid rgba(188, 188, 203, 0.5);
				height: vh(4.6);
				outline: none;

				border-radius: 25px;
				padding-right: 4%;
				font: normal normal medium vh(1.5) Rubik;
				font-size: vh(1.5);
				font-weight: medium;
				color: var(--custom-dark-blue);
			}
			.input-title {
				font-size: vh(1.8);
				line-height: vh(2.1);
				font-weight: 600;
				color: var(--custom-dark-blue);
				margin-bottom: vh(1.85);
				margin-right: 4%;
			}
			.input-error {
				content: "";
				height: vh(2.1);
				color: red;
				font-size: vh(1.7);
				margin: vh(0.5) 4% 0 0;
			}
			&:first-child {
				// name input
				width: 40%;
				margin-bottom: vh(3.5);
			}
			&:nth-child(2) {
				// email input
				width: 50%;
			}
			&:nth-child(3) {
				// text area for message
				width: 100%;
				margin-bottom: vh(5);

				.input-title,
				.input-error {
					margin-right: 2%;
				}

				.input {
					padding: vh(1) vh(2);
					border-end-end-radius: 5px;

					resize: vertical;
					min-height: 10vh;
					max-height: 20vh;
				}
			}
			.grow-wrap {
				/* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
				display: grid;
			}
			.grow-wrap::after {
				/* Note the weird space! Needed to preventy jumpy behavior */
				content: attr(data-replicated-value) " ";
				/* This is how textarea text behaves */
				white-space: pre-wrap;
				/* Hidden from view, clicks, and screen readers */
				visibility: hidden;
			}

			.grow-wrap > textarea {
				/* You could leave this, but after a user resizes, then it ruins the auto sizing */
				resize: none;
				/* Firefox shows scrollbar on growth, you can hide like this. */
				overflow: hidden;
			}

			.grow-wrap > textarea,
			.grow-wrap::after {
				/* Identical styling required!! */
				border: 1px solid black;
				padding: 0.5rem;
				font: inherit;
				/* Place on top of each other */
				grid-area: 1 / 1 / 2 / 2;
			}
			.required {
				color: var(--custom-error-red);
				font-size: 1em;
				line-height: 1.3em;
				font-weight: bold;
			}
		}
	}
	.submit-button {
		font: normal normal bold 1em/1.3em Rubik;
		font-size: 1em;
		line-height: 1.3em;
		font-weight: bold;
		background-color: var(--custom-pink);
		padding: vh(1.1) vh(4.7);
		border-radius: 25px;
		box-shadow: 0px 3px 6px #00000029;
		margin-right: 15.31vw;
	}
}

@media only screen and (max-width: 1024px) {
	.contact-us {
		margin: 0;
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.title {
			margin-top: vh(5);
			font-size: vh(3);
		}
		.description {
			margin-bottom: vh(4);
			font-size: vh(2);
		}
		.pre-form {
			width: 77%;
		}
		.form {
			width: 77%;
			margin-right: unset;

			flex-direction: column;
			.input-container {
				margin: 0;
				&:nth-child(1),
				&:nth-child(2) {
					width: 100%;
				}

				&:nth-child(3) {
					.input {
						resize: none;
						height: 17vh;
						padding: 2vh 1.5vh;
					}
				}
				.input-title {
					margin-right: 0;
				}
				.input {
					height: vh(6);
					width: 100%;
				}
			}
		}
		.submit-button {
			width: 62vw;
			height: vh(6);
			margin-right: unset;
		}
	}
}
