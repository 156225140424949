.PlayerL {
  position: fixed;
  bottom: 0;
  z-index: 3;
}

.background {
  position: absolute;
  bottom: 10vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  background-color: #eb6868;
  color: white;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  width: 100vw;
  height: 10vh;
  position: static;
  box-shadow: 0 0 7px 2px #17171757;
}

.playLitlle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20vw;

  img {
    cursor: pointer;
    width: 3.5vh;
    height: 3.5vh;
  }
}
.playImg {
  height: 6vh;
  width: 6vh;
  border: 2px solid white;
  border-radius: 100%;
  z-index: 4;
  background-color: inherit;

  img {
    width: 6vh;
    height: 6vh;
  }
}

.leftSide {
  width: 80vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .bookInfoL {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    height: 6vh;
    .bookTitleNameL {
      font-size: 1.97vh;
      font-weight: bold;
      line-height: 2.33vh;
      overflow: hidden;
      white-space: nowrap;
    }

    .bookCTL {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .bookTimeL {
        font-weight: 300;
        font-size: 1.6vh;
        line-height: 2.1vh;
      }
      .bookChapterL {
        font-weight: 300;
        font-size: 2.21vh;
        line-height: 2.46vh;
      }
    }
  }


  .book-imgL {
    width: 6.65vh;
    height: 6.65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vh;
    box-shadow: 0 0 7px 2px #17171757;
    img {
      height: 100%;
      width: 100%;
    }
    .bookTitle {
      font-size: 0.98vh;
      font-weight: bold;
      line-height: 1.1vh;
    }
  }
  

  .arrowBL {
    // transform: rotate(180deg);
    height: 1.6vh;
    margin-top: -4vh;

    &--img {
      cursor: pointer;
      height: 1.6vh;
    }
  }
}

.browser {
  .background {
    border-radius: 0;
    bottom: 0;

    .emptySpace {
      width: 40vw;
    }

    .leftSide {
      width: 40vw;
      margin-right: 10vw;
    }

    .bookInfoL {
      width: 40%;
    }
  }
}
