.red-rpopup{
    background: var(--custom-pink);
    animation: move-from-top 8s 2s;
}

.blue-rpopup{
    animation: move-from-top 4s 2s;
    background: var(--custom-medium-blue);
}

.pink-popup {
    position: absolute; // postion fixed to the top of the page in the center (desktop mode)
    top: 15vh;
    right: 50%;
    transform: translateX(50%);
    width: 25vw;
    height: 6.5vh;


    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    font-size: 3vh;
    font-weight: normal;
    opacity: 0;
    color: white;

    display: flex; // center the text inside in the center
    align-items: center;
    justify-content: center;

    @keyframes move-from-top {
        0% {
            opacity: 0;
            transform: translateX(60%);
        }
        5% {
            opacity: 1;
            transform: translateX(50%);
        }
        95% {
            opacity: 1;
            transform: translateX(50%);
        }
        100% {
            opacity: 0;
            transform: translateX(40%);
            display: none;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .pink-popup {
        height: 7vh;
        .welcome {
            font-size: 3vh;
        }
    }
}
@media only screen and (max-width: 768px) {
    .pink-popup {
        height: 7vh;
        width: 60vw;
        top: unset;
        bottom: 15vh;

        .welcome {
            font-size: 2.5vh;
        }
    }
    @keyframes move-from-top {
        0% {
            opacity: 0;
            transform: translate(50%,50px);
        }
        10% {
            opacity: 1;
            transform: translate(50%,0);
        }
        90% {
            opacity: 1;
            transform: translate(50%,0);
        }
        100% {
            opacity: 0;
            transform: translate(50%,50px);
            display: none;
        }
    }
}

//! this is from the previous version of this component{
// .pink-popup:before {
//     content: "";
//     background: inherit;
//     z-index: -1;
//     position: absolute;
//     height: 2.5vh;
//     width: 9vh;
//     bottom: -25%;
//     left: 0%;
// }

// .pink-popup:after {
//     content: "";
//     background: #fff;
//     z-index: -1;
//     position: absolute;
//     height: 2.5vh;
//     width: 9vh;
//     bottom: -25%;
//     left: 0.1%;
//     border-top-left-radius: 120px 70px;
// }
//!}
