// scss

$default-color:#cecece;
// recommended to be the same color (looks nice) :
$bg-color: $default-color;
$border-color: $default-color;
$cancel-color: $default-color;
$cancel-border-color: $default-color;



#general-alert-container {
    color: white;

    z-index: 15;

    margin: 0 auto;
    margin-bottom: 1rem;
    margin-left: 2rem;
    border: 1px solid transparent;
    border-radius: 0.2rem;
    padding: 0 10px;

    position: absolute;
    bottom: 0;
    left: 0;
    // right: 0;

    // width: fit-content !important;
    display: table;
    height: 2.5rem;
    line-height: 2.5rem; //must be the same as the height
}
.timeout-animation {
    animation: alert-msg-animation;
    animation-duration: 5s;
    opacity: 0;
}
.warning-color {
    background-color: rgb(219, 103, 103);
}
.default-color {
    background-color: rgba(0, 0, 0, 0.5);
}
.center {
    margin: 0 auto !important;
    margin-bottom: 1rem !important;
    left: 0 !important;
    right: 0 !important;
    // transform: translate(50%)!important;
}

@keyframes alert-msg-animation {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}