@import "../../style/changing-sizes.scss";

.rating-popup {
    width: 100vw;
    font-family: "Rubik";
    position: relative;

    .saved-popup:not(.choose-class-popup-container) {
        width: 62vw;
    }

    .saved-popup {
        position: absolute;
        top: 50vh;
        border: none;
        color: black;
        border-radius: 15px;

        .close-popup {
            margin-right: 0vw;

            .popup-icon {
                margin-right: 2.25vw;
                margin-top: 2.25vw;
            }
        }

        .schoolTitle {
            font-weight: bold;
        }

        .popup-content {
            padding: vh(1) 2vw;

            .stars-fractional-rating {
                font-size: vh(3.5);
                height: vh(3.5);
            }

            .error-star {
                color: var(--custom-pink);
            }

            .no-error-star {
                color: var(--custom-yellow);
            }

            .input-titles {
                font-size: vh(2.25);
            }

            .review-fields {
                width: 55vw;
                margin: auto;

                .popup-flex {
                    width: 55vw;
                    display: flex;
                    justify-content: space-between;
                }

                .ratings-flex {
                    display: grid;
                    grid-template-columns: 28vw 27vw;
                    margin-top: vh(4);
                }

                .big-flex {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .popup-input {
                    display: flex;
                    justify-content: space-between;

                    .errors-flex {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;

                        .star-flex {
                            width: 24vw;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: vh(2.5);
                        }

                        .header-flex {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                        }

                        .error {
                            width: 10vw;
                            text-align: left;
                        }

                        .review {
                            width: 17vw;
                            color: var(--custom-pink);
                            font-size: vh(1.85);
                            margin-right: 0;
                        }
                    }
                }

                label {
                    font-size: 2.25vh;
                }

                textarea {
                    resize: none;
                }

                select {
                    width: 7vw;
                    border-radius: 10px;
                    background-color: white;
                    outline: none;
                    padding: vh(0.75) 0.75vw;
                    font-size: vh(2);
                    font-family: "Rubik";
                }

                .no-error-border {
                    border: 1px solid black;
                }

                .error-border {
                    border: 1px solid var(--custom-pink);
                }

                #titleInput {
                    margin-top: vh(1);
                    border-radius: 10px;
                    height: vh(4);
                    width: 24vw;
                    outline: none;
                    padding: vh(0.75) 0.75vw;
                    font-size: 1.5vh;
                    font-family: "Rubik";
                }

                #feedbackInput {
                    margin-top: 1vh;
                    border-radius: 10px;
                    height: vh(30);
                    outline: none;
                    padding: vh(0.75) 0.75vw;
                    font-size: vh(2);
                    width: 24vw;
                    font-family: "Rubik";
                }
            }

            .popup-button {
                width: 18vw;
                font-size: vh(3);
                margin: vh(3) auto vh(2) auto;
                padding: vh(1);
                background-color: var(--custom-pink);

                &:hover {
                    transform: scale(1.05, 1.05);
                    transition: 0.2s;
                }
            }
        }
    }

    @media only screen and (max-width: 1100px) {
        .saved-popup {
            width: 70vw;

            .popup-content {
                .input-titles {
                    font-size: vh(2);
                }

                .review-fields {
                    width: 60vw;

                    .popup-flex {
                        width: 60vw;
                    }

                    .ratings-flex {
                        grid-template-columns: 31vw 29vw;
                    }

                    .popup-input {
                        .errors-flex {
                            .star-flex {
                                width: 26vw;
                            }
                        }
                    }

                    label {
                        font-size: vh(2);
                    }

                    #feedbackInput {
                        height: vh(28);
                        width: 26vw;
                    }
                }

                .popup-button {
                    width: 22vw;
                    font-size: vh(2.5);
                }
            }
        }
    }

    @media only screen and (max-width: 950px) {
        .saved-popup {
            width: 75vw;

            .popup-content {
                .stars-fractional-rating {
                    font-size: vh(3.25);
                    height: vh(3.25);
                }

                .review-fields {
                    width: 65vw;

                    .popup-flex {
                        width: 65vw;
                    }

                    .ratings-flex {
                        grid-template-columns: 33vw 32vw;
                    }

                    .popup-input {
                        .errors-flex {
                            .star-flex {
                                width: 29vw;
                            }
                        }
                    }

                    #titleInput {
                        width: 29vw;
                    }

                    #feedbackInput {
                        height: vh(28);
                        width: 29vw;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .saved-popup {
            width: 80vw;

            .popup-content {
                .stars-fractional-rating {
                    font-size: vh(3);
                    height: vh(3);
                }

                .review-fields {
                    width: 70vw;

                    .popup-flex {
                        width: 70vw;
                    }

                    .ratings-flex {
                        grid-template-columns: 36vw 34vw;
                    }

                    .popup-input {
                        .errors-flex {
                            .star-flex {
                                width: 32vw;
                            }
                        }
                    }

                    #titleInput {
                        width: 32vw;
                    }

                    #feedbackInput {
                        width: 32vw;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .saved-popup {
            width: 85vw;

            .popup-content {
                .input-titles {
                    font-size: vh(2);
                }

                .review-fields {
                    width: 75vw;

                    .popup-flex {
                        width: 75vw;
                    }

                    .ratings-flex {
                        grid-template-columns: 38vw 37vw;
                    }

                    .popup-input {
                        .errors-flex {
                            .star-flex {
                                width: 35vw;
                            }
                        }
                    }

                    label {
                        font-size: vh(2);
                    }

                    #titleInput {
                        width: 35vw;
                    }

                    #feedbackInput {
                        height: vh(28);
                        width: 35vw;
                    }
                }

                .popup-button {
                    width: 26vw;
                }
            }
        }
    }
}

.phone-version {
    .rating-popup {
        .saved-popup {
            width: 90vw;
            height: vh(95);
            top: 46vh;

            .schoolTitle {
                font-size: vh(4.5);
                line-height: vh(4.5);
            }

            .close-popup {
                .popup-icon {
                    margin-right: 4vw;
                    height: vh(2.5);
                    color: inherit;
                }
            }

            .popup-content {
                padding: 0 2vw;

                .input-titles {
                    font-size: vh(2.75);
                }

                .popup-button {
                    width: 50vw;
                    font-size: vh(3);
                }

                .stars-fractional-rating {
                    font-size: vh(4.5);
                    height: vh(4.5);
                    align-self: flex-end;
                }

                .review-fields {
                    margin-top: vh(3);
                    margin-right: 7.5vw;

                    #titleInput {
                        width: 75vw;
                        height: vh(5.5);
                        font-size: vh(2);
                    }

                    #feedbackInput {
                        width: 75vw;
                        height: vh(20);
                        font-size: vh(2);
                    }

                    label {
                        font-size: vh(2.75);
                    }

                    .big-flex {
                        margin-top: 0.5vh;
                    }

                    .popup-input {
                        .errors-flex {
                            .error {
                                width: 50vw;
                                font-size: vh(1.75);
                            }

                            .review {
                                width: 50vw;
                                font-size: vh(1.75);
                            }

                            .star-flex {
                                width: 75vw;
                                margin-top: vh(1);
                                align-items: center;
                            }

                            label {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.bounce-7 {
    animation-duration: 1.5s;
    animation-name: bounce-7;
}

@keyframes bounce-7 {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.05, 0.95) translateY(0);
    }

    30% {
        transform: scale(0.95, 1.05) translateY(0);
    }

    50% {
        transform: scale(1.01, 0.99) translateY(0);
    }

    57% {
        transform: scale(1, 1) translateY(0);
    }

    60% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}
