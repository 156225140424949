@import "../style/changing-sizes.scss";

.nav-container {
    background-color: var(--custom-dark-blue);
    height: vh(10);
    width: vw(100);
    display: flex;
    align-items: center;

    .nav-web-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        padding-right: 4vw;

        .nav-logo {
            .nav-img {
                cursor: pointer;
                height: vh(6);
            }
        }

        .nav-left-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 6vw;
            width: 75vw;

            .long {
                width: 35vw;
            }

            .sort {
                width: 26vw;
            }

            .nav-links {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .nav-list-item {
                    font-size: vh(2.3);
                    font-weight: 500;
                    color: rgba(255, 255, 255, 0.671);
                    text-decoration: underline var(--custom-dark-blue);
                }

                :hover {
                    cursor: pointer;
                }

                .currentPage {
                    color: white;
                    font-weight: 600;
                }
            }
        }

        .header-drop-down {
            position: relative;

            .header-profile {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: white;

                .line {
                    height: vh(4);
                    border-right: 0.8px solid white;
                }

                .profile-name {
                    font-size: vh(2.3);
                    font-weight: 600;
                    padding-right: 1.5vw;
                    padding-left: 0.2vw;
                    cursor: pointer;
                    width: max-content;
                }

                .profile-pic {
                    height: vh(4);
                    cursor: pointer;
                }
            }

            .dropdown-content {
                display: none;
                position: absolute;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                background-color: #ffffff;
                border-radius: 5px;
                font-size: vh(2.3);
                font-weight: 500;
                min-width: 9vw;
                left: 0;

                a {
                    color: #0c3e89;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .dropdown-log-out-icon {
                        height: vh(2);
                        margin-left: 0.5vw;
                    }
                }

                :hover {
                    background-color: #ddd;
                    border-radius: 5px;
                }
            }

            .show {
                display: block;
            }
        }
    }

    .nav-mobile-container {
        .nav-title {
            color: white;
            font-size: vh(3.8);
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .nav-mobile-container {
        .nav-left-container {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            align-items: center;
            width: 100vw;
            height: vh(10);
            padding-right: 3vw;
            padding-left: 3vw;

            .nav-title {
                display: flex;
                width: 70vw;
                justify-content: center;
            }

            .back-arrow-img-mobile {
                height: vh(5);
                background-color: unset;
                border: unset;
                padding-right: 2vw;
                padding-left: 2vw;

            }

            .nav-img {
                height: vh(5);

                &__hamburger {
                    color: white;
                }
            }

            .nav-img-logo {
                height: vh(5.8);
            }
        }
    }
}