@import "../style/changing-sizes.scss";

.menu-popup {
    background-color: var(--custom-dark-blue);
    height: 100%;
    width: 70%;

    &:focus {
        outline: 0;
        border: none;
    }

    .menu-inside {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: vh(3);
        height: 100%;

        padding-right: 7vw;

        hr {
            color: rgba(255, 255, 255, 0.671);
            width: 92%;
        }

        .menu-top-auth {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: vh(4);
            width: 90%;

            .header-profile {
                align-self: flex-start;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: white;
                margin-bottom: vh(3);
                margin-right: 3vw;
                width: 90%;

                .profile-name {
                    font-size: vh(2.5);
                    font-weight: 600;
                    padding-left: 3vw;
                    cursor: pointer;
                }

                .profile-pic {
                    height: vh(5.5);
                    cursor: pointer;
                }
            }
        }

        .long {
            margin-top: vh(-23);
            height: vh(35);
        }
        .sort {
            margin-top: vh(6);
            height: vh(32);
        }

        .nav-links {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: fit-content;

            .nav-list-item {
                font-size: vh(2.3);
                font-weight: 500;
                color: rgba(255, 255, 255, 0.671);
                text-decoration: underline var(--custom-dark-blue);
                -webkit-text-decoration-line: underline;
                -webkit-text-decoration-color: var(--custom-dark-blue);
            }

            .currentPage {
                color: white;
                font-weight: 600;
            }
        }

        .menu-bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 90%;

            .text-bottom {
                font-size:vh(1.8) ;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.671);
                margin-top: vh(0.5);
            }

            .first {
                margin-top: vh(3);
            }

            .bottom-img {
                margin-top: vh(3);
                height: vh(3.2);
            }
        }

        .menu-button {
            align-self: center;
        }
    }
}
