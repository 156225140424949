@import "changing-sizes.scss";

* {
	//   @import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
	padding: 0;
	margin: 0;
}

.header {
	.title {
		// margin-right: auto;
		// margin-right: vh(1.5);
		// margin-bottom: vh(1.5);
		font-size: vh(4);
		display: flex;
		text-align: right;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
		// width: 90%;
	}

	.profile {
		margin: 2vh;
		margin-top: 1vh;
	}
}

.footer-general {
	position: fixed;
	bottom: -1px;
	display: flex;
	align-content: center;
	background: #ffffff 0% 0% no-repeat padding-box;
	width: 100%;
	height: vh(10.5);
}

.ios-footer-general {
	height: vh(10.75);
}

.footer {
	box-shadow: 0px 0px 10px #0000006b;
	border-radius: 22px 19px 0px 0px;
	z-index: 3;
}

.playerFooter {
	z-index: 4;
}

.nav {
	font-size: vh(2);
	font-family: Rubik;
	font-weight: normal;
	margin: auto;
	letter-spacing: 0px;
	color: var(--custom-footer-blue);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 80%;
	text-decoration: none;
	cursor: default;
	padding-bottom: vh(0.5);
	padding-top: vh(0.5);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ios-nav {
	padding-bottom: vh(1);
	padding-top: vh(0.5);
}

.footerIcon {
	height: vh(4.5);
	width: vh(4.5);
	-webkit-mask-size: vh(4.5) vh(4.5);
	background-color: var(--custom-footer-blue);
	cursor: default;

	&:focus {
		outline: 0;
	}
}

.currentPage {
	color: var(--custom-pink);

	.footerIcon {
		background-color: var(--custom-pink);
	}
}

.footer-web {
	height: vh(10.5);
	width: 100vw;
	background-color: var(--custom-footer-blue);
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	font-family: "Rubik";
	z-index: 3;

	.footer__position-fixed {
		position: fixed;
		bottom: 0;
	}

	.logos {
		width: 24%;
		margin-left: -8vw;
		margin-right: 2.2vw;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		.img-footer-web {
			height: vh(5);
		}
	}

	.text-footer-web {
		margin-left: 8vw;
		font-size: vh(2.2);
		color: white;
	}

	.link-footer-web {
		font-size: vh(2.2);
		color: white;
		text-decoration: underline;
		cursor: pointer;
	}
}

.footer-web-with-bottom-audio {
	margin-top: -8vh;
	margin-bottom: 10vh;
	position: unset;
	bottom: unset;
}

.contactUs-footer-audio {
	margin-top: 8vh;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
	.footer-web {
		.logos {
			margin-left: unset;

			.img-footer-web {
				height: vh(3.5);
			}
		}

		.text-footer-web {
			font-size: vh(1.8);
		}

		.link-footer-web {
			font-size: vh(1.8);
		}
	}
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
	.footer-web {
		.logos {
			margin-left: -3vw;

			.img-footer-web {
				height: vh(4);
			}
		}

		.text-footer-web {
			font-size: vh(1.9);
		}

		.link-footer-web {
			font-size: vh(1.9);
		}
	}
}

// .mobile-search {
// 	margin-top: vh(4);
// 	display: flex;
// 	align-items: center;
// 	background: #f7f7f7 0% 0% no-repeat padding-box;
// 	box-shadow: 0px 3px 6px #00000029;
// 	border-radius: 20px;
// 	width: 90vw;
// 	margin-right: 4.5vw;
// }

// .search {
// 	margin-top: vh(0.5);
// 	display: flex;
// 	align-items: center;
// 	background: #f7f7f7 0% 0% no-repeat padding-box;
// 	box-shadow: 0px 3px 6px #00000029;
// 	border-radius: 20px;
// 	width: 90vw;
// }

#searchInput {
	width: 100%;
	border: none;
	background: none;
	margin: vh(1);
	letter-spacing: 0px;
	color: #0d0d0d;
	outline: none;
	vertical-align: middle;
	font-size: 18px;
	line-height: 22px;
	font-weight: 300;
	font-size: vh(2);
	cursor: text;
}

#searchInput::-webkit-input-placeholder {
	font-size: vh(2);
}

.button {
	border: none;
	background: none;
	margin: vh(1.5) vh(1);
	padding-right: vh(1);
	outline: none;

	&:focus {
		outline: solid 1px;
	}
}

#searchButton {
	margin-right: 2vw;
	vertical-align: middle;
}

#clearButton {
	margin-left: 1vh;
	vertical-align: middle;
}

.results-number {
	font-family: Rubik;
	font-weight: normal;
	color: gray;
	font-size: vh(2.1);
	width: vh(20);
	height: vh(2);
	padding-right: vh(1);
	white-space: nowrap;
}

//WEB DESIGN
// .web-version {
// 	.header {
// 		height: 14vh;
// 		background-color: white;
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;
// 		align-items: center;
// 		border-width: 100%;

// 		padding-right: 11vw;

// 		.title {
// 			margin-right: 1.5vh;
// 			padding-bottom: 1vh;
// 			padding-right: 0;

// 			color: black;
// 			font-weight: bolder;
// 			font-size: 6vh;
// 			height: 8vh;
// 			display: flex;
// 			align-items: center;
// 		}

// 		.search {
// 			width: 40vh;
// 			height: 6vh;
// 			margin-left: 25vh;
// 			align-items: center;
// 			border-style: solid;
// 			border-radius: 15px;
// 			border: 1px solid black;
// 			box-shadow: none;

// 			#searchInput {
// 				font-size: 2vh;
// 			}

// 			#searchInput::-webkit-input-placeholder {
// 				font-size: vh(2);
// 			}

// 			#searchButton {
// 				margin-right: 1vh;
// 			}

// 			img {
// 				height: 4vh;
// 			}

// 			.categories {
// 				margin: 0;
// 			}

// 			#clearButton {
// 				height: 3vh;
// 				margin-right: 0;
// 				vertical-align: middle;
// 				cursor: pointer;
// 			}

// 			.results-number {
// 				height: auto;
// 				font-size: 1.5vh;
// 				padding-right: 2vh;
// 				padding-top: 0;
// 				width: 16vh;
// 			}
// 		}
// 	}
// }

@media only screen and (max-width: 768px) {
	.nav {
		font-size: 12px;
	}
}

input,
input:before,
input:after,
textarea,
textarea:before,
textarea:after {
	-webkit-user-select: initial;
	-khtml-user-select: initial;
	-moz-user-select: initial;
	-ms-user-select: initial;
	user-select: initial;
}

@media screen and(max-width: 768px) {
	#enable-toolbar {
		#enable-toolbar-trigger {
			#enable-toolbar-trigger-svg {
				height: 42px;
				width: 32px;
			}

			left: 4%;
			right: unset;
		}
	}
}