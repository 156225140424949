@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: bold;

  // src: url('path/to/OpenSans.eot');
  src: local("Rubik"), local("Rubik"), url("./Rubik/static/Rubik-Bold.ttf") format("truetype");
}

* {
  font-family: Rubik;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: lighter;

  // src: url('path/to/OpenSans.eot');
  src: local("Rubik"), local("Rubik"), url("./Rubik/static/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  // font-weight: 700;

  // src: url('path/to/OpenSans.eot');
  src: local("Rubik"), local("Rubik"), url("./Rubik/Rubik-VariableFont_wght.ttf") format("truetype");
}
.App {
  direction: rtl;
}

*::-webkit-scrollbar {
  width: 1vw;
}
*::-webkit-scrollbar-track {
  width: 1vw;

}

*::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 2vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --custom-yellow: #fddd4b;
  --custom-pink: #ee5c5c;
  --custom-light-pink: #ee5c5c93;
  --custom-dark-blue: #0c3e89;
  --custom-medium-blue: #48a6e1;
  --custom-light-blue: #23cdfd;
  --custom-footer-blue: #4aa2df;
  --custom-header-cyan: #9ad0f5;
  --custom-error-red: #fb4e5d;
  --custom-light-grey: #eeedec;
  --custom-green: #1d98a1; // 1d98a1
}

.App-header {
  background-color: #282c34;
  min-height: vh(100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  // user-select: none;
  -webkit-user-select: none;
  // -webkit-user-callout: none;
  // -webkit-touch-callout: none;

  -webkit-user-drag: none;
  // -khtml-user-drag: none;
  // -moz-user-drag: none;
  // -o-user-drag: none;
  // -user-drag: none;
}

/*input, textarea {  
  user-select: text;
  -webkit-user-select: text;
  -webkit-user-callout: default;
  -webkit-touch-callout: default;
} */

.footer-margin {
  margin-bottom: 7vh;
}

.player-margin {
  margin-bottom: 18vh;
}

.no-player-phone {
  margin-bottom: 12vh;
}

.player-phone {
  margin-bottom: 20vh;
}

// .contact-web {
//   margin-bottom: 6.5vh;
// }

.profile-footer {
  margin: 0;
}

.noPhoneTeacher {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 3vh;
  line-height: 4vh;
  font-weight: bold;
}

.exit {
  margin-top: -25vh;
  text-align: center;
  font-size: 2vh;
  font-weight: bold;
  line-height: 3vh;
  color: var(--custom-dark-blue);
  text-decoration: underline;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  30% {
    transform: scale(0.95, 1.05) translateY(0);
  }

  50% {
    transform: scale(1.01, 0.99) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(0);
  }

  60% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.trigger-position-right {
  overflow-x: hidden;
}

// .errorServer{
//   background-color: rgba($color: #fc00008c, $alpha: 0.5);
//   z-index: 5;
//   width: 20vw;
//   text-align: center;
//   // height: 7vh;
//   position: absolute;
//   right: 2vw;
//   top: 20vh;
//   // border: 1px solid black;
//   border-radius: 20px ;
//   // padding: 15px;
//   box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// }

// android:windowSoftInputMode:"adjustPan"
