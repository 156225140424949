@import "changing-sizes.scss";

.teacher-profile {
    height: 40vh;
}

.phone-profile {
    font-family: "Rubik";
}

.trophy-container {
    position: relative;
    text-align: center;
    color: black;
}

.trophy-books {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    font-size: 2.75vh;
}
// .profile-buttons {
//     background: none;
//     color: inherit;
//     border: none;
//     padding: 0;
//     font: inherit;

//     outline: inherit;
//     cursor: pointer; // remove all button css

//     display: flex;
//     align-items: flex-end;
//     margin-left: 10vh;

//     margin-right: auto; // force to left
//     order: 2;
//     * {
//         cursor: pointer;
//     }
//     .edit-profile {
//         font-size: 1.3em;
//         color: var(--custom-pink);
//         text-decoration: underline;
//     }
//     .edit-image {
//         width: 2.7vh;
//         margin-left: 0.5vh;
//     }
// }

// .profile-header {
//     height: 8vh;
//     display: flex;
//     align-items: center;
//     width: 100%;
//     justify-content: space-between;
//     padding-left: 4vw;
//     border-bottom: 1px solid #c3c3c3;

//     .profile-title {
//         font-size: vh(3.5);
//         line-height: bold;
//         font-weight: vh(8);
//         margin: 0 3vh;
//         text-align: right;
//         letter-spacing: 0px;
//         opacity: 1;
//         overflow: hidden;
//         height: vh(8);
//         color: black;
//     }
// }

#quote {
    padding: 1.5vh;
    text-align: center;
    width: 90vw;
    max-width: 650px;
    margin: 4vh auto 4vh auto;
    background: var(--custom-pink);
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    position: relative;
}

#quote:before {
    content: "";
    background: inherit;
    z-index: -1;
    position: absolute;
    height: 10vh;
    width: 9vh;
    bottom: -20%;
    left: 0%;
}

#quote:after {
    content: "";
    background: #fff;
    // background: chartreuse;
    z-index: -1;
    position: absolute;
    height: 5.7vh;
    width: 10vh;
    bottom: -5.7vh;
    left: 0.1%;
    border-top-left-radius: 120px 60px;
}

.booksRead {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vh;
}

.goodJob {
    width: calc(80vw - 2.5vh);
    overflow: hidden;
    white-space: nowrap;
}

.ranking {
    margin-bottom: -5vh;
    height: 12vh;
}

.rankingBrowser {
    cursor: pointer;
}

.pencil {
    z-index: 1;
    height: 5vh;
    padding-right: 15vw;
}

.pencilBrowser {
    cursor: pointer;
    padding-right: 5vw;
}

.profileCenter {
    margin-bottom: 4vh;
}

.personalInfo {
    background: var(--custom-dark-blue) 0% 0% no-repeat padding-box;
    color: white;

    h3 {
        font-weight: normal;
        cursor: pointer;
    }

    .no-pointer {
        cursor: default;
    }
}

.profileLevel {
    text-align: center;
}
.profileLevelImg {
    align-items: center;
}

.edit-profile-img {
    background-color: inherit;
    border: none;
    height: fit-content;
    margin-top: 3vh;
}

.blindLibrary {
    font-family: Rubik;
    background-color: #eb6868;
    border: none;
    color: white;
    width: 150px;
    height: 50px;
    font-size: 16px;
    // outline: none;

    &:focus {
        outline: black solid 10x;
    }
}

.personalFlex {
    display: flex;
    justify-content: space-between;
    padding: 5vh;
    padding-bottom: 3vh;

    p {
        padding-top: 0.5vh;
        font-size: vh(1.75);
    }
}

.contactUs {
    margin: 0vh 5vh 2vh 12vw;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: white;
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.settings {
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 2vh 5vh 2vh 10vw;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: white;
    align-items: center;
}

.settings-icon {
    height: 3.5vh;
}

.logOut {
    padding: 1vh 5vh 5vh 11vw;
    display: flex;
    justify-content: space-between;
    background-color: inherit;
    border: none;
    cursor: pointer;
    text-decoration: none;
    color: white;
    align-items: center;
}

.profile-header {
    width: 100vw;
    overflow: auto;
    overflow: overlay;
    padding-right: 8%;
    padding-left: 8%;
    height: 12vh;
    border-bottom: 1px solid #9e9c9c;
    font-family: Rubik;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile-title {
        display: flex;
        align-items: center;
        font-weight: bolder;
        font-size: 5.5vh;
    }

    .profile-buttons {
        border: none;
        background-color: #eeeeee;
        padding: 1vh 2.5vh;
        font-size: 2vh;
        border-radius: 30px;
        align-items: center;
        box-shadow: 0 3px 5px 0.5px#cdcdcddf;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .edit-profile {
            color: var(--custom-dark-blue);
            text-decoration: none;
            cursor: pointer;
        }
        .edit-image {
            width: 2.5vh;
            margin-left: 0.5vh;
            cursor: pointer;
        }
    }
}

.web-version {
    font-family: Rubik;
    margin-bottom: 0vh;
    // overflow: hidden auto;

    .profileCenter {
        margin-bottom: unset;
        margin-top: vh(8);
    }

    .trophy-books {
        font-size: 2.25vh;
    }

    #quote {
        margin-top: 7vh;
        border-radius: 10px;
    }

    #quote:after {
        content: "";
        background: #fff;
        z-index: -1;
        position: absolute;
        height: 4vh;
        width: 9vh;
        bottom: -20%;
        left: 0.1%;
        border-top-left-radius: 120px 60px;
    }

    .booksRead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1vh 5vh;
    }

    .good-job {
        display: flex;
        flex-direction: column;
        text-align: right;
        font-size: 4vh;
    }

    .profileLevel {
        margin: 10vh auto;
    }

    .web-stuff {
        max-width: 400px;
        margin: auto;
    }

    .blindLibrary {
        cursor: pointer;
        height: 45px;
        font-size: 18px;
    }

    .personalFlex {
        padding: 5vh 0vh;

        p {
            padding-top: 0.5vh;
            font-size: vh(2);
        }
    }

    .settings {
        width: 270px;
        padding: 2vh 0vh 8vh 0vh;
    }

    .settings-icon {
        cursor: pointer;
    }

    .logOut {
        // display: none;
        width: 270px;
        padding: 2vh 0vh 8vh 0vh;
    }
}

@media only screen and (max-width: 768px) {
    .logOut {
        padding-bottom: 11vh;
    }
    .profile-header {
        height: 8vh;

        .profile-title {
            font-size: 3.5vh;
        }

        .profile-buttons {
            font-size: 1.5vh;
        }
    }
}
