.spinner {
    text-align: center;
    padding: 0px 5px;
    margin: 0 8%;

    & > div {
        width: 10px;
        height: 10px;
        background-color: white;
        margin-right: 4px;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bounce-delay 1.4s infinite ease-in-out both;
        animation: sk-bounce-delay 1.4s infinite ease-in-out both;
    }

    .bouncer {
        &1 {
            -webkit-animation-delay: 0s;
            animation-delay: 0s;
        }

        &2 {
            -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
        }

        &3 {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
    }
}

@-webkit-keyframes sk-bounce-delay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce-delay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
