// .container3211 {
// margin: 0 auto;
// padding: 0px 2vw 2vw 2vw;
// width: 90vw;
// }

.sliderElement {
  // margin: 1vw;
  padding: 2%;
  position: relative;
  text-align: center;
  border: none;
}

.slick-track {
  padding: 2% 0;
}

.slick-slide {
  border: none;
  div:focus {
    outline: none;
  }
}
.slick-arrow {
  z-index: 5 !important;
}
.slick-next:before,
.slick-prev:before {
  z-index: 5 !important;
  color: #000;
}

/* .center  */
.slick-center .sliderElement {
  opacity: 1;
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.35s ease;
}
