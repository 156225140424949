@import "../style/changing-sizes.scss";

.open-phone {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: vh(100);
	overflow: hidden;

	.imgin {
		margin-top: vh(8);
		width: 49.46vw;
		height: vh(6.157);
		// font-size: vh(2.5);
	}

	.make-new-account {
		font-size: vh(2.25);
	}

	.imglogo {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 4;
	}

	.library {
		font-size: vh(3);
		margin-bottom: revert;
		color: #555555;
		font-weight: bold;
	}

	& .login-flex {
		.imgin {
			margin-top: 0;
		}

		.imglogo {
			transition: 1s;
			height: vh(20);
			transform: scale(0.7, 0.7);

			.imgop {
				height: vh(17);
				transition: 1s;
			}

			.library {
				transform: scale(1.3, 1.3);
				margin-bottom: vh(1);
				margin-top: vh(1);
			}
		}

		.login-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			&.form-height-1 {
				height: vh(10);
			}

			&.form-height-2 {
				height: vh(20);
			}

			.login-input {
				display: flex;
				width: 65vw;
				align-items: center;
				justify-content: space-between;
				margin-right: -15vw;
			}

			div {
				display: flex;
				align-items: center;
				width: 50vw;
			}

			input {
				align-self: center;
				border: none;
				outline: none;
				border-radius: vh(10);
				height: vh(5.5);
				min-width: 50vw;
				font-size: revert;
				margin: 0 0;
				overflow: auto;
				background-image: none !important;
				background-color: #f7f7f7;
				box-shadow: 0px 3px 6px #00000029;
				padding-right: vh(1);
			}

			svg {
				height: vh(3);
				position: relative;
				left: 2vw;
				z-index: 1;
				margin-right: -5vw;
				float: left;
				cursor: default;
			}

			.error-border {
				border: 2px solid var(--custom-pink);
			}

			.no-error-border {
				border: none;
			}
		}
	}
}

.open-web {
	width: 100vw;
	overflow: hidden;
	position: relative;
	height: 100vh;

	.imglogo {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1vh;
		.imgop {
			width: 20vw;
		}
	}

	.library {
		margin-bottom: 5vh;
		color: #555555;
		font-weight: bold;
	}

	.imgin {
		width: 25vw;
		&.alink {
			width: 22vw;
		}
		min-width: 250px;
		height: 5.5vh;
		font-size: 2.25vh;
		// cursor: pointer;

		// &:hover {
		// 	transform: scale(1.05, 1.05);
		// 	transition: 0.2s;
		// }
	}

	.imgtop {
		width: 20vw;
	}

	.imgbottom {
		width: 20vw;
	}

	& .login-flex {
		.imglogo {
			transition: 1s;
			height: 7vw;
			min-height: 150px;
			transform: scale(0.7, 0.7);

			.imgop {
				transition: 1s;
				// height: 5vw;
				min-height: 100px;
			}

			.library {
				transform: scale(1.3, 1.3);
				margin-bottom: 0;
				margin-top: 1.5vh;
			}
		}

		.form-height-1 {
			height: 10vh;
		}

		.form-height-2 {
			height: 20vh;
		}

		.login-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;

			.login-input {
				display: flex;
				width: 28vw;
				min-width: 300px;
				justify-content: space-between;
				align-items: center;
				margin-right: -6vw;

				@media only screen and (max-width: 1175px) {
					margin-right: -50px;
				}
			}

			input {
				align-self: center;
				border: none;
				outline: none;
				border-radius: 25px;
				height: 5.5vh;
				width: 22vw;
				min-width: 250px;
				font-size: revert;
				margin: 0 0;
				overflow: auto;
				background-image: none !important;
				background-color: #f7f7f7;
				box-shadow: 0px 3px 6px #00000029;
				padding-right: 1vh;
			}

			label {
				font-size: 2vh;
			}

			svg {
				height: 3vh;
				position: relative;
				left: 1vw;
				z-index: 1;
				margin-right: -2.5vw;
				margin-top: 1.25vh;
				float: left;
				cursor: pointer;
			}

			.error-border {
				border: 2px solid var(--custom-pink);
			}

			.no-error-border {
				border: none;
			}

			.error {
				width: 21vw;
				text-align: right;
				margin-bottom: 2vh;
			}

			.error-all {
				width: 22vw;
				font-size: 2.5vh;
				line-height: 3.5vh;
				height: 3.5vh;
				margin-right: 6vw;

				@media only screen and (max-width: 1175px) {
					margin-right: 50px;
				}
			}
		}
	}

	.links-signup {
		display: flex;
		margin-top: 0.5vh;

		.line-div {
			width: 1px;
			background-color: var(--custom-pink);
			height: 2.5vh;
			margin: 0 1vw;
		}

		.forgot-pass {
			margin-bottom: 0;
		}
	}

	.forgot-pass-form {
		margin-top: 15vh;

		.error-pass {
			font-size: 1.55vh;
		}
	}
}

.visibility {
	visibility: hidden;
	height: 0vh;
}

.form {
	transition: 1s;
}

.imgtop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
}

.library {
	color: black;
	font-family: "Rubik";
	font-style: normal;
	font-weight: normal;
}

.alink {
	display: flex;
	color: white;
	font-family: "Rubik";
	font-style: normal;
	font-weight: bold;
	cursor: pointer;
	align-items: center;
	justify-content: center;
}

.imgbottom {
	position: fixed;
	z-index: -1;
	top: vh(91);
	right: 0;
}

.make-new-account {
	display: flex;
	text-align: center;
	justify-content: center;
}

.no-account {
	color: var(--custom-pink);
	margin-left: 0.3rem;
	font-family: Rubik;
	font-size: 2vh;
}

.signup-link {
	text-decoration: underline;
	color: var(--custom-pink);
	background: none;
	font-family: Rubik;
	font-size: vh(2);

	a {
		color: var(--custom-pink);
		cursor: pointer;
	}
}

.imgin {
	margin-bottom: vh(1.5);
	// text-decoration: none;
	background-color: var(--custom-pink);
	// border-radius: 25px;
}

.forgot-pass {
	margin-bottom: vh(3);
}

.open-flex {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: 1s;
	height: vh(100);
	width: 100vw;
}

.go-to-about {
	position: absolute;
	bottom: vh(4);
}
