/* ----------------------------------------------
 * Generated by Animista on 2020-11-3 11:16:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
	0% {
		-webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
		transform: translateY(-600px) rotateX(-30deg) scale(0);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) rotateX(0) scale(1);
		transform: translateY(0) rotateX(0) scale(1);
		-webkit-transform-origin: 50% 1400px;
		transform-origin: 50% 1400px;
		opacity: 1;
	}
}

@keyframes slide-in-elliptic-top-fwd {
	0% {
		-webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
		transform: translateY(-600px) rotateX(-30deg) scale(0);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0) rotateX(0) scale(1);
		transform: translateY(0) rotateX(0) scale(1);
		-webkit-transform-origin: 50% 1400px;
		transform-origin: 50% 1400px;
		opacity: 1;
	}
}

.slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 1) both;
	animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 1) both;

	.web-version {
		.book-page {
			height: 100vh;
			width: 100vw;

			.head-of-the-page {
				background-color: #eeedec;

				.back-to-lidrary {
					padding-top: 3vh;
					background: none;
					border: none;
					padding-right: 5vw;
					font-family: Rubik;
					font-size: 2.8vh;
					cursor: pointer;
					outline: none;
				}

				.front {
					display: flex;
					justify-content: space-around;
					padding-left: 1vw;
					padding-right: 1vw;
					padding-top: 4vh;
					padding-bottom: 5vh;

					.img-info-box {
						display: flex;
						flex-direction: row;

						.book-info {
							align-items: flex-start;
							display: flex;
							flex-direction: column;
							margin-right: 5vh;

							.book-title {
								font-family: Rubik;
								font-weight: bold !important;
								text-align: center;
								letter-spacing: 0px;
								padding-top: 2.5vh;
								font-size: 3.5vh;
							}

							.book-author {
								text-align: center;
								font-size: 2.8vh;
								padding-bottom: 3vh;
							}

							.time-of-book {
								display: flex;
								flex-direction: row;
								align-items: center;
								.clock-img {
									height: 3vh;
									padding-left: 0.5vw;
								}
							}

							.no-padding {
								padding-right: 0;
								margin-right: 0;
								font-weight: normal;
							}

							.genRating {
								display: flex;
								justify-content: space-around;
								align-items: flex-start;
								margin: 1vh;
								padding: 1vh;
								font-size: 16px;
								.genR {
									font-size: 16px;
								}

								.seeMore {
									padding-left: 9px;
									font-size: 1.5vh;

									a {
										color: white;
										cursor: pointer;
									}
								}
							}

							h4 {
								margin: 0;
							}
						}
					}
				}

				.popup-exists {
					z-index: 1;
				}

				.bpButton {
					font-size: 2.8vh;
					width: 17vw;
					min-width: 150px;
					height: 6vh;
					cursor: pointer;
					box-shadow: 0px 3px 6px #00000029;

					* {
						cursor: pointer;
					}
				}

				.first-button {
					color: black;
					background-color: white;
					// margin-top: 5vh;

					.text-on-button {
						display: flex;
						flex-direction: row;
						align-items: center;

						.play {
							padding-left: 0.8vw;
							height: 2vh;
						}
					}
				}
			}

			.bottom {
				margin-top: 6vh;
				padding-right: 12vw;
				padding-left: 9vw;
				padding-bottom: 13vh;

				.book-page-summary {
					font-family: Rubik;
					font-size: 2.8vh;

					.book-page-summary-title {
						font-weight: bold;
						text-align: center;
						letter-spacing: 0px;
						font-size: 3.5vh;
						margin-bottom: 0.5vh;
						text-align: right;
					}

					.book-page-summary-text {
						margin-bottom: 3vh;
					}
				}
			}

			.end-info {
				.little-title {
					font-weight: bold;
				}

				p {
					margin-bottom: 0.5vh;
				}
			}

			.bottom-player {
				top: 10vh;
			}

			.book-length {
				font-size: 2.5vh;
			}
		}
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// margin-top: 2vh;
}

.bpButton {
	background-color: #eeedec;
	border-radius: 20px;
	text-align: center;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0px;
	color: #040404;
	width: 60vw;
	height: 6vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.play {
		width: 3vh;
		padding-left: 1vh;
	}
}

.bpButton {
	font-size: 2.5vh;
	width: 17vw;
	min-width: 150px;
	cursor: pointer;

	* {
		cursor: pointer;
	}
}

.addShelf {
	border: none;
	background-color: var(--custom-pink);
	color: #ffffff;
}

@media screen and (max-width: 768px) {
	.slide-in-elliptic-top-fwd {
		.book-page {
			.head-mobile {
				width: 100vw;
				background-color: #eeedec;

				.back-to-lidrary-mobile {
					padding-top: 3vh;
					background: none;
					border: none;
					padding-right: 5vw;
					font-family: Rubik;
					font-size: 2.8vh;
					cursor: pointer;
					outline: none;

					img {
						height: 3vh;
					}
				}

				.front {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding-left: 1vw;
					padding-right: 1vw;
					// padding-top: 4vh;
					padding-bottom: 5vh;

					.book-title {
						font-family: Rubik;
						font-weight: bold;
						text-align: center;
						letter-spacing: 0px;
						font-size: 3.5vh;
					}

					.book-author {
						text-align: center;
						font-size: 2.8vh;
						font-weight: 500;
						padding-bottom: 3vh;
					}

					.book-img {
						border-radius: 4vh;
					}

					.time-of-book {
						display: flex;
						flex-direction: row;
						align-items: center;

						padding-top: 2vh;

						.clock-img {
							height: 2.8vh;
							padding-left: 1.5vw;
						}
					}

					.book-length {
						font-size: 2.8vh;
						font-weight: 500;
					}
				}
			}
		}
		.bottom {
			margin-top: 6vh;
			padding-right: 12vw;
			padding-left: 9vw;

			.book-page-summary {
				font-family: Rubik;
				font-size: 2.8vh;

				.book-page-summary-title {
					font-weight: bold;
					text-align: center;
					letter-spacing: 0px;
					font-size: 3.5vh;
					margin-bottom: 0.5vh;
					text-align: right;
				}

				.book-page-summary-text {
					margin-bottom: 3vh;
				}
			}
		}

		.end-info {
			.little-title {
				font-weight: bold;
			}

			p {
				margin-bottom: 0.5vh;
			}
		}
	}

	.buttons {
		margin-top: 3vh;
		margin-bottom: 4vh;
	}

	.bpButton {
		font-size: 3vh;
		width: 55vw;
		border-radius: 4vh;
	}
}
