@import "changing-sizes.scss";

.linkB {
    color: black;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    padding-bottom: vh(0.5);
    font-size: 2em;
    font-weight: bold;
    width: fit-content;

    .arrow-move {
        margin-right: vh(1);
        display: inline-block;
        transition: all 0.3s ease-in-out;
    }
}

@media screen and (max-width: 768px) {
    .linkB {
        font-size: 1.5em;
    }
}
