@import "../style/changing-sizes.scss";

.setting {
    height: fit-content;
    .input {
        align-self: center;
        border: solid 1px #c8c8c8b7;
        outline: none;
        border-radius: 10vh;
        height: 5.5vh;
        width: 22vw;
        min-width: 200px;
        margin: 0 0;
        overflow: auto;
        background-image: none;
        // background-color: #f7f7f7;
        padding: 2vh;
        font-size: vh(2.5);
        font-weight: 500;
    }

    .student-profile {
        height: vh(79.5);
        .title {
            text-align: start;
            padding-right: 10vw;
            padding-top: vh(2);
            padding-bottom: vh(1);
            font-size: vh(4.2);
            border-bottom: 1px solid #dadada;
        }

        .student-profile-split {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 90%;
            .student-profile-right-side {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: right;
                border-left: 1px solid #dadada;

                .mini-title {
                    margin-top: vh(6);
                    margin-right: 4vw;
                    font: normal normal 600 vh(3.5) Rubik;
                    font-size: vh(3.5);
                    font-weight: 600;
                    white-space: nowrap;
                }

                .right-side-inputs {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    height: 65%;
                    margin-top: vh(3);

                    .input {
                        height: vh(7);
                        background-color: #e0e0e0b7;

                        width: 20vw;
                    }

                    label {
                        font-size: vh(3.2);
                        font-weight: 500;
                        text-align: center;
                    }

                    .name-change {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        width: 30vw;

                        .new-name {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .input {
                                cursor: text;
                                margin-top: vh(3);
                            }

                            .setting-error {
                                color: var(--custom-pink);
                                margin-right: 0.5vw;
                                height: vh(2);
                                font-size: vh(1.65);
                                margin-top: vh(1);
                            }
                        }
                    }
                    .class-change {
                        .new-class {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            width: 30vw;

                            .input {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .settingButton {
                    width: 20vw;
                    height: vh(7);
                    text-align: center;
                    font-size: vh(3);
                    font-weight: 500;
                    background-color: var(--custom-pink);
                    color: white;
                    border-radius: vh(4);
                    cursor: pointer;
                    border: none;
                    margin-top: -10vh;

                    &:hover {
                        transform: scale(1.05, 1.05);
                        transition: 0.3s;
                    }
                }
            }

            .student-profile-left-side {
                width: 50%;
                display: flex;
                flex-direction: column;

                .mini-title {
                    margin-top: vh(6);
                    margin-right: 4vw;
                    font-size: vh(3.5);
                    font-weight: 600;
                    white-space: nowrap;
                }

                .left-side-inputs {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 60%;

                    .rank-profile {
                        font-size: vh(3.5);
                        font-weight: 600;
                        margin-bottom: vh(1);
                    }

                    .edit-profile-img {
                        .profileLevelImg {
                            .ranking {
                                height: vh(20);
                            }
                        }

                        .pencil {
                            height: vh(7);
                            padding-right: 8vw;
                            margin-top: vh(-2);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .setting {
        .student-profile {
            height: vh(80);

            .title {
                display: none;
            }

            .student-profile-split {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-right: 6%;
                padding-left: 6%;

                .student-profile-right-side {
                    width: 100%;
                    height: 50%;
                    align-items: center;
                    border-left: unset;

                    .mini-title {
                        display: none;
                    }

                    .right-side-inputs {
                        width: 100%;
                        justify-content: space-between;
                        height: 75%;
                        margin-top: unset;

                        .input {
                            width: 50vw;
                        }

                        label {
                            font-size: vh(3.2);
                            font-weight: 500;
                            min-width: 20vw;
                            width: fit-content;
                        }

                        .name-change {
                            padding-top: 3vh;
                            flex-direction: column;
                            width: 68vw;
                        }

                        .class-change {
                            .new-class {
                                width: 68vw;
                                margin-top: -2vh;
                            }
                        }
                    }

                    .settingButton {
                        width: 50vw;
                        height: vh(8);
                        margin-right: unset;
                    }
                }

                .student-profile-left-side {
                    width: 100%;
                    height: 50%;

                    align-items: center;
                    .mini-title {
                        // margin-right: 9vw;
                        margin-right: 0vw;

                        margin-bottom: vh(2);
                        align-self: auto;
                    }

                    .left-side-inputs {
                        flex-direction: column-reverse;
                        justify-content: space-evenly;
                        width: 80%;

                        .rank-profile {
                            .the-rank {
                                font-size: vh(3.5);
                                font-weight: 500;
                                margin-top: vh(1);
                            }
                        }

                        .edit-profile-img {
                            .pencil {
                                padding-right: 12vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 570px) {
    .setting {
        .student-profile {
            .student-profile-split {
                .student-profile-right-side {
                    .right-side-inputs {
                        padding-left: 6%;

                        .input {
                            width: 42vw;
                        }

                        label {
                            width: 28vw;
                        }

                        .name-change {
                            width: 75vw;
                        }
                        .class-change {
                            .new-class {
                                width: 75vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 440px) {
    .setting {
        .student-profile {
            .student-profile-split {
                .student-profile-right-side {
                    .right-side-inputs {
                        .name-change {
                            width: 95vw;
                        }

                        .class-change {
                            .new-class {
                                width: 95vw;
                            }
                        }

                        label {
                            width: 100vw;
                            font-size: vh(2.8);
                        }
                    }
                }

                .student-profile-left-side {
                    .left-side-inputs {
                        .rank-profile {
                            .the-rank {
                                font-size: vh(2.8);
                            }
                        }
                    }
                }
            }
        }
    }
}
