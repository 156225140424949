.allStudentTable {
    background-color: #f2efef;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Significant {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vw;

    .titleS {
        font-size: 1.6vw;
        line-height: 2vw;
        font-weight: bold;
    }

    .authorS {
        font-size: 1.3vw;
        line-height: 1.8vw;
        font-weight:100 ;
    }

    .durationS {
        font-size: 1.1vw;
        line-height: 1.6vw;
    }
}

.listenMode {
    width: 75vw;
    font-size: 3vw;
    line-height:1.8vw ;
    font-weight: bold;
    padding: 0.5vw;
    margin: 1vw 0;
}

.table {
    margin-bottom: 8vw;
    border: 0.5px solid black;

    .th {
        display: flex;
        width: 75vw;
        height: 4vw;
        justify-content: space-around;
        border: 0.5px solid black;
        background-color: #fff;

        .tt {
            width: 20vw;
            white-space: nowrap;
            text-align: center;
            font-size: 1.2vw;
            line-height:4vw ;
            font-weight: bold;
        }
    }

    .td {
        display: flex;
        width: 75vw;
        height: 5.5vw;
        justify-content: space-around;
        border: 0.5px solid black;
        background-color: #fff;

        .ttd {
            width: 20vw;
            white-space: nowrap;
            text-align: center;
            font-size:1vw ;
            line-height:5.5vw ;
        }

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;

            .progress {
                width: 1.1vw;
                height: 1.1vw;
                border-radius: 100%;
                border: 0.5px solid #707070;
            }
        }

        .dark-red {
            background-color: #c9281b;
        }

        .green {
            background-color: #1bc97f;
        }

        .orange {
            background-color: #fdad15;
        }

        .currentTime {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 2vw;
            .container1 {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 3.6vw;
                }
                .progressbar-text {
                    font-size: 0.9vw;
                    line-height:1.5vw ;
                    font-weight: 300;
                    color: #181519 !important;
                }
            }
        }
    }
}
