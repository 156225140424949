@import "changing-sizes.scss";

.container {
	display: flex;
	padding: 1vh;
	// width: 100vw;
	// padding-right: 2.5vw;
	// padding-top: vh(2);
	// height: vh(22);
	overflow: hidden;
	// margin: auto;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 4vw;
	height: vh(18);
	margin: 1vh 0;
}

.title {
	// padding-right: 1vw;
	margin: 0;
	text-align: right;
	font-size: 14px;
	font-weight: bold;
	color: #030303;
	overflow: hidden;
	height: fit-content;
	// width: 100%;
}

.age-category-header {
	.first-side{
		display: flex;
		flex-direction: row;
		align-items: center;
		
	}
}

.age-category-search{
	margin-top: 2vh;
}

.infiniteScroll {
	margin-bottom: 10vh;
}

.init {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95vw;
	margin-top: vh(5);

	.no-more-books {
		margin-right: 2vw;
		margin-top: vh(7);
		margin-bottom: vh(10);
		font-size: 2.5vh;
		font-weight: 500;

		a:hover {
			cursor: pointer;
		}

		a:visited {
			color: black;
		}
	}
}

.no-results-container {
	margin: 10vh 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.no-result-error {
		margin-top: 1vh;
		max-width: 80vw;
		overflow: hidden;
		white-space: pre-wrap;
		text-align: center;
		text-overflow: ellipsis;
	}
}

.no-results {
	width: 80vw;
	overflow: hidden;
	flex-flow: wrap;
	font-weight: bold;
	text-align: center;
	color: var(--custom-dark-blue);
	font-size: vh(2.5);
}

.sad-emoji {
	// margin-bottom: 1vh;
	// justify-self: center;
	// width: 20vw;
	padding-top: vh(10);
	width: 20vh;
}

.no-padding {
	padding-right: 0 !important;
}

.catalog-buttons {
	height: 30vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// margin-top: 2vh;

	.bpButton {
		box-shadow: 1px 3px 6px #dad6d6;
	}
}

.web-version {
	.no-results-container {
		padding-bottom: vh(10);

		.sad-emoji {
			padding-top: vh(10);
			width: 20vh;
		}

		.no-results {
			font-size: 3vh;
		}
	}

	.infiniteScroll {
		.container {
			width: 90vw;
			margin: auto;
			// padding-right: 12vw;
			height: 31vh;
		}
	}

	.title {
		padding-right: 0;
		padding-bottom: 1vh;
		padding-top: 0 !important;
		font-size: 2.5vh;
	}

	.info {
		width: 40vw;
		padding-right: 4vh;
		justify-content: center;
		height: 29vh;
		margin-top: auto;
	}

	.catalog-buttons {
		margin-right: auto;
		// margin-left: 10vw;
		// padding-bottom: 7vh;
	}

	.shadow {
		height: 0.5vh;
		border-bottom: 1px solid #9e9c9c;
		box-shadow: 0px 4px 6px #dad6d6;
		margin-bottom: 5vh;
	}

	.no-padding {
		padding-right: 0 !important;
	}

	@media only screen and (max-width: 1175px) {
		.info {
			width: 35vw;
		}
	}

	@media only screen and (max-width: 1050px) {
		.info {
			width: 38vw;
		}
	}

	@media only screen and (max-width: 785px) {
		.s-genRating {
			width: 25vh;
		}

		.s-genR {
			font-size: 2vh;
			padding-left: 1vh;
		}

		.star {
			height: 2vh;
		}
	}
}

@media only screen and (max-width: 768px) {
	.search-container-top {
		.search {
			display: flex;
			align-items: center;
			background: #f7f7f7 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 20px;
			width: 90vw;
			border: 1px solid black;
			margin-right: 5%;
			margin-left: 6%;
		}
	}
}
