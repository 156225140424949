// @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700;800&display=swap');

.tagsPopup {
  direction: rtl;
  text-align: center;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
}
.MuiBackdrop-root {
  font-family: "Heebo" !important;
  background-color: #00000071 !important;
}

.game .MuiBackdrop-root {
  background-color: #ffffff99 !important;
}

.MuiDialog-paperWidthSm {
  box-shadow: 10px 10px 10px #0000000a !important;
  //   max-width: unset !important;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
} //עיגול פואפ

.containTagsPopup {
  .MuiDialog-paper {
    margin: 15px !important;
    overflow: hidden;
    min-height: 95%;
    max-height: 95%;
  } // סידור פואפ

  .MuiDialogContent-root {
    height: 100%;
    padding: 0;
  } // סידור פואפ
}

.containTagsPopupBrowser {
  // סידור פואפ

  .MuiDialog-container .MuiDialog-scrollPaper {
    align-items: center;
  }
  .tagsPopup {
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
  }

  //הכללי

  .containTags {
    // background-color: violet;
    margin: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 7vh 4vh 7vh 4vh;
    margin-top: 3px;
  }

  .MuiPaper-root {
    width: 100vw;
  }

  .containOneTag {
    margin: 2vw 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33%;
    flex-direction: column;

    .profile-img {
      padding: 0;
      background-color: inherit;
      border: none;
      width: 80%;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .profile-img-button {
        background-color: inherit;
        border: none;
      }
    }
  }
  .containOneTagImg {
    cursor: pointer;
  }

  .grayTag {
    filter: grayscale(100%);
  }

  .containXinTagsPopup {
    // flex-direction: column;
    height: fit-content;
    align-items: start;
    width: 100%;
    display: flex;
    padding: 0vh 2vh 2vh 2vh;
    height: 12%;
    background-image: linear-gradient(white 70%, rgba(255, 255, 255, 0));
    position: absolute;
    z-index: 3;

    .profile-popup-title {
      width: 100%;
      align-self: center;
      text-align: center;
      color: var(--custom-dark-blue);
      font-size: 3.4vh;
      margin-left:2vw;
      font-family: Rubik;
    }
  }
}

.close-profile-popup {
  background-color: inherit;
  border: none;
  padding: 0;
  align-self: center;
  // margin: 3vh 0 1vh;
  float: right;
  width: 2.3vh;
  cursor: pointer;

  img {
    width: 100%;
    cursor: pointer;
  }
}

.tagsPopup {
  width: calc(100vw - 30px);
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
}
//הכללי

.containTags {
  margin: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  padding: 7vh 2vh 7vh 2vh;
  margin-top: 3px;
}

// .containTags {
//   // margin: auto;
//   width: 85%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   overflow-y: auto;
//   padding: 7vh 2vh 7vh 2vh;
//   margin-top: 3px;
// }
.containOneTag {
  margin: 2vw 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
  flex-direction: column;
}

.MuiPaper-root {
  width: 100vw;
}

.grayTag {
  filter: grayscale(100%);
}

.containXinTagsPopup {
  align-items: center;
  height: fit-content;
  align-items: start;
  width: 100%;
  display: flex;
  padding: 0vh 2vh 2vh 2vh;
  height: 9vh;
  background-image: linear-gradient(white 70%, rgba(255, 255, 255, 0));
  position: absolute;
  z-index: 3;

  .profile-popup-title {
    width: 100%;
    align-self: center;
    text-align: center;
    color: var(--custom-dark-blue);
    font-size: 3.4vh;
  }
}

.gradientBottom {
  width: 100%;
  height: 30px;
  padding: 1.5vh 0 3vh 0;
  background-image: linear-gradient(
    to top,
    white,
    white,
    white,
    white,
    white,
    rgba(255, 255, 255, 0.521),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.9vh;
  color: #00000099;
}

@media screen and (min-width: 500px) {
  .containPopup {
    width: 400px;
  }
}

@media screen and (min-width: 700px) {
  .containPopup {
    width: 600px;
  }
}

@media screen and (min-width: 800px) {
  .containPopup {
    width: 700px;
  }
}

$loadPicturesVeryFast: 25;

@for $i from 0 through $loadPicturesVeryFast + 1 {
  .loadPicturesVeryFast-#{$loadPicturesVeryFast + 1-$i} {
    -webkit-animation: scale-in-center
      0.3s
      cubic-bezier(0.25, 0.46, 0.45, 0.94)
      #{($loadPicturesVeryFast + 1-$i) /
      8}s
      both;
    animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) #{($loadPicturesVeryFast + 1-$i) / 8}s both;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
