.page-error {
    margin: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    height: 50vh;

    .back-button {
        background-color: #45d4fccb;
        padding: 1.5vh;
        border-radius: 2.5vh;
        border: none;
        display: flex;
        align-items: center;
        font-size: 2vh;
        cursor: pointer;
        box-shadow: 1px 1px 7px 2px #3b3b3b27;

        &--arrow {
            cursor: pointer;
            height: 1.5vh;
            margin-left: 1vw;
        }

        .text {
            cursor: pointer;
        }

        &:focus {
            outline: solid;
        }
    }

    .warning-icon {
        height: 15vh;
        width: 15vh;
    }

    .message {
        font-size: 3vh;
        white-space: pre-wrap;
        text-align: center;
    }
}
