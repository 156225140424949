.loader-container {
	display: flex;
	justify-content: center;
	padding-top: 5vh;
	padding-bottom: 2vh;
}

.loader {
	border: 5px solid white;
	border-top: 5px solid var(--custom-dark-blue);
	border-right: 5px solid var(--custom-dark-blue);
	border-bottom: 5px solid var(--custom-dark-blue);
	border-radius: 50%;
	width: 45px;
	height: 45px;
	animation: spin 1.5s linear infinite;
}

.pinkLoader {
	border: 5px solid white;
	border-top: 5px solid var(--custom-pink);
	border-right: 5px solid var(--custom-pink);
	border-bottom: 5px solid var(--custom-pink);
	border-radius: 50%;
	width: 45px;
	height: 45px;
	animation: spin 1.5s linear infinite;
}

.whiteLoader {
	border: 5px solid   white;
	border-top: 5px solid white ;// rgba(255, 255, 255, 0);
	border-right: 5px solid white;//rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	border-radius: 50%;
	width: 45px;
	height: 45px;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}