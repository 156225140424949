@import "changing-sizes.scss";

.privacy-terms-page {
    height: vh(100);
    width: 100vw;
    // overflow: auto;
    // overflow: overlay;

    .privacy-terms-title {
        display: flex;
        align-items: center;
        font-weight: bolder;
        font-size: 5.5vh;
        padding-right: 8%;
        height: 12vh;
        border-bottom: 1px solid #9e9c9c;
        font-family: Rubik;
    }

    
    .privacy-terms-texts {
        padding-top: vh(4);
        padding-bottom: vh(8);
        padding-right: 8%;
        padding-left: 4%;
        display: flex;
        flex-direction: column;
        align-items: right;
        width: 90vw;

        .privacy-terms-part {
            padding-bottom: vh(7);
            font-family: Rubik;

            .privacy-terms-text-title {
                font-weight: bolder;
                font-size: 4.5vh;
                padding-bottom: vh(2.5);
                width: fit-content;
            }

            .privacy-terms-red {
                background-color: #fb4e5d;
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .privacy-terms-yellow {
                background-color: var(--custom-yellow);
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .privacy-terms-blue {
                background: var(--custom-medium-blue);
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .privacy-terms-purple {
                background-color: #1dd3b0;
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .privacy-terms-orange {
                background-color: #fe7f2d;
                height: vh(1);
                border-radius: vh(4);
                margin-top: vh(1);
            }

            .privacy-terms-inside {
                display: flex;
                flex-direction: column;

                .part {
                    margin-bottom: vh(4);
                }
            }
        }
    }

    .privacy-width {
        width: 87vw;
    }
}

@media screen and (max-width: 1024px) {
    .privacy-terms-page {
        .privacy-terms-title {
            display: none;
        }

        .privacy-terms-texts {
            .privacy-terms-part {
                padding-bottom: vh(8);
            }
        }
    }
}
